
export default {
  name: 'MktCountrySelectionBanner',
  methods: {
    switchLocale() {
      let newPath = this.switchLocalePath(
        this.$i18n.locale === 'au' ? 'nz' : 'au'
      );
      if (newPath === '/nz') {
        newPath = '/nz/wills';
      }
      this.$router.push({
        path: newPath,
      });
      this.close();
    },
    close() {
      this.$emit('close');
    },
  },
};
