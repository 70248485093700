
export default {
  name: 'MktPricingToggle',
  props: {
    value: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      modelValue: null,
    };
  },
  watch: {
    modelValue(modelValue) {
      this.$emit('input', modelValue);
    },
    value(value) {
      this.modelValue = value;
    },
  },
  mounted() {
    this.modelValue = this.value;
  },
};
