
export default {
  name: 'MktSafewillLogo',
  props: {
    iconColor: {
      type: String,
      default: '#67d7c0',
    },
  },
  computed: {
    height() {
      return this.$mq === 'default' ? 36 : 46;
    },
    width() {
      return this.$mq === 'default' ? 119 : 152;
    },
  },
};
