
import anime from 'animejs';

export default {
  name: 'MktWillGraphic',
  props: {
    action: {
      required: true,
      type: String,
      validate(action) {
        return ['review', 'sign'].includes(action);
      },
    },
  },
  data() {
    return {
      anim: null,
      actionAnim: null,
      isVisible: false,
    };
  },
  mounted() {
    this.anim = anime.timeline({
      autoplay: false,
      complete: () => {
        this.$scroll.trigger();
      },
    });

    this.anim
      .add({
        targets: this.$el,
        translateY: {
          value: [90, 0],
          duration: 850,
          easing: 'easeOutQuart',
        },
        opacity: {
          value: [0, 1],
          duration: 250,
          easing: 'easeInOutQuad',
        },
        translateZ: 0,
      })
      .add(
        {
          targets: this.$refs.lines.children,
          strokeDashoffset: [anime.setDashoffset, 0],
          duration: 650,
          easing: 'easeInOutQuad',
          delay: anime.stagger(40, { start: 200 }),
        },
        '-=800'
      );

    this.actionAnim = anime.timeline({ autoplay: false });

    switch (this.action) {
      case 'review':
        this.actionAnim
          .add({
            targets: this.$refs.lens,
            translateX: [-50, 0],
            translateY: [30, 0],
            translateZ: 0,
            rotate: [-20, 0],
            opacity: {
              value: [0, 1],
              duration: 250,
              easing: 'easeInOutQuad',
            },
            transformOrigin: 'bottom right',
            easing: 'easeOutCubic',
            duration: 650,
          })
          .add({
            targets: this.$refs.text,
            opacity: [0, 1],
            duration: 1,
          })
          .add({
            targets: this.$refs.lens,
            translateX: [0, `${this.$el.offsetWidth}px`],
            translateZ: 0,
            duration: 3000,
            easing: 'easeInOutQuad',
          })
          .add(
            {
              targets: this.$refs.text,
              translateX: '-100%',
              translateZ: 0,
              duration: 3000,
              easing: 'easeInOutQuad',
            },
            '-=3000'
          );
        break;
      case 'sign':
        this.actionAnim.add({
          targets: this.$refs.signature,
          strokeDashoffset: [anime.setDashoffset, 0],
          duration: 1000,
          easing: 'easeInOutQuad',
        });
        break;
      default:
        break;
    }
  },
  methods: {
    onScroll() {
      const { isVisible, visible } = this.$rect(this.$el, true);
      const playAction = visible === 1;

      if (!this.isVisible && isVisible) {
        this.anim.play();
        this.isVisible = true;
      }

      if (this.anim.completed && playAction) {
        this.actionAnim.play();
      }

      return this.isVisible && this.anim.completed && playAction;
    },
  },
};
