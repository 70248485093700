
export default {
  name: 'MktBaseGlyph',
  props: {
    type: {
      type: String,
      required: true,
    },
    classes: {
      default: null,
      type: String,
    },
  },
};
