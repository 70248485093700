
import { mapGetters } from 'vuex';
import reviewsData from '~/google-reviews.json';
import { partnerships, transition, affiliates } from '~/mixins';
import { isFreeWillWeek } from '~/util';

export default {
  name: 'MktWillsContent',
  mixins: [partnerships, transition, affiliates],
  props: {
    data: {
      default: null,
      type: Object,
    },
    hideSaleBanner: {
      default: false,
      type: Boolean,
    },
    ignorePartnershipCookie: {
      default: false,
      type: Boolean,
    },
    hidePartnership: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      reviewsData,
    };
  },
  computed: {
    ...mapGetters(['partnership', 'theme', 'hasPartnerCookie']),
    partneredEntity() {
      return this.partnership;
    },
    showPartnership() {
      return !this.hidePartnership && !!this.partnership;
    },
    showSaleBanner() {
      if (this.ignorePartnershipCookie) {
        return this.saleBanner.bannerEnabled;
      } else if (this.hideSaleBanner || !this.saleBanner.bannerEnabled) {
        return false;
      } else if (this.hasPartnerCookie === true) {
        return this.saleBanner.overrideCharityBanner;
      } else if (this.hasPartnerCookie === false) {
        return true;
      }
      return false;
    },
    saleBanner() {
      return !!this.data.banner && this.data.banner[0];
    },
    hero() {
      if (!this.showPartnership) {
        return this.data.hero[0];
      }

      const title =
        isFreeWillWeek() && !!this.partneredEntity.fwwHeaderContent[0]?.heading
          ? this.partneredEntity.fwwHeaderContent[0].heading
          : this.partneredEntity.headerContent[0].heading;
      const content =
        isFreeWillWeek() && !!this.partneredEntity.fwwHeaderContent[0]?.body
          ? this.partneredEntity.fwwHeaderContent[0].body
          : this.partneredEntity.headerContent[0].body;
      return {
        image: this.partneredEntity.headerImage,
        title,
        content: `<p>${content}</p>`,
        enabled: this.data.hero[0].enabled,
      };
    },
    mediaTrustEnabled() {
      return !!this.data.mediaTrust && this.data.mediaTrust;
    },
    valuePropositions() {
      return !!this.data.valuePropositions && this.data.valuePropositions[0];
    },
    socialReviews() {
      return !!this.data.socialReviews && this.data.socialReviews[0];
    },
    callToAction() {
      return !!this.data.callToAction && this.data.callToAction[0];
    },
    legal() {
      return !!this.data.legal && this.data.legal[0];
    },
    articles() {
      return !!this.data.articles && this.data.articles[0];
    },
    faq() {
      return !!this.data.faqs && this.data.faqs[0];
    },
    support() {
      return !!this.data.support && this.data.support[0];
    },
    pricing() {
      return (
        this.hasPartnerCookie === false &&
        !!this.data.pricing &&
        this.data.pricing[0]
      );
    },
    features() {
      return !!this.data.features && this.data.features[0];
    },
  },
};
