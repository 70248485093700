
export default {
  name: 'MktSocialButton',
  props: {
    /* eslint-disable-next-line vue/prop-name-casing */
    __typename: {
      required: true,
      type: String,
    },
    platform: {
      required: true,
      type: String,
      validate(platform) {
        ['facebook', 'instagram', 'linkedin', 'messenger'].includes(platform);
      },
    },
    url: {
      required: true,
      type: String,
    },
  },
};
