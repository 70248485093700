
import authorProps from '~/modules/author-props';

export default {
  name: 'MktBlogAuthorLarge',
  props: {
    ...authorProps(),
    details: {
      default: false,
      type: Boolean,
    },
  },
};
