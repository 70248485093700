
export default {
  name: 'MktCountryDropdown',
  data() {
    return {
      countries: {
        au: { name: 'Australia', shortname: 'AU', flag: '🇦🇺' },
        nz: { name: 'New Zealand', shortname: 'NZ', flag: '🇳🇿' },
      },
    };
  },
  computed: {
    selectedCountry() {
      return this.countries[this.$i18n.locale];
    },
  },
  methods: {
    menuItemClass(locale) {
      const isActive = this.$i18n.locale === locale;
      return {
        'bg-teal-100': isActive,
        'hover:bg-teal-100 cursor-pointer': !isActive,
      };
    },
  },
};
