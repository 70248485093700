
export default {
  name: 'MktAppCardCharityInvite',
  props: {
    type: {
      required: true,
      type: String,
      validate(type) {
        return ['a', 'b'].includes(type);
      },
    },
  },
};
