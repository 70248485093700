
export default {
  name: 'MktProbateFaqItem',
  props: {
    answer: {
      required: true,
      type: String,
    },
    title: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      isExpanded: false,
    };
  },
};
