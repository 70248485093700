
import GET_GUIDANCE_NOTES_QUERY from '~/graphql/queries/GetGuidanceNotes';

import { craft, transition, partnerships } from '~/mixins';

export default {
  name: 'PageGuidanceNotes',
  mixins: [
    craft({
      query: GET_GUIDANCE_NOTES_QUERY,
      isLocalizable: true,
    }),
    transition,
    partnerships,
  ],
  data() {
    return {
      entry: null,
    };
  },
};
