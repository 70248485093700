
import { mapActions } from 'vuex';
import cookies from 'js-cookie';
import GET_AFFILIATE_QUERY from '~/graphql/queries/GetAffiliate';
import GET_WILL_QUERY from '~/graphql/queries/GetWill';
import { craft } from '~/mixins';
import { affiliates } from '~/vanityUrls.json';

export default {
  name: 'PageAffiliate',
  mixins: [craft({ keepLocale: false })],
  data() {
    return {
      entry: null,
    };
  },
  async fetch() {
    const routeName = this.$route.name?.split('___')[0];
    const affiliate = cookies.get('affiliate');
    const countryCode = this.$i18n.locale.toUpperCase();
    const vanityUrl = affiliates.find(
      (p) => p.slug === routeName && p.country.includes(countryCode)
    );
    const vanitySlug = vanityUrl?.slug;
    const [willQuery, affiliateQuery] = await Promise.all([
      this.$apollo.query({
        query: GET_WILL_QUERY,
        variables: {
          country: countryCode,
        },
      }),
      this.$apollo.query({
        query: GET_AFFILIATE_QUERY,
        variables: {
          id: vanitySlug,
          country: countryCode,
        },
      }),
    ]);
    this.entry = this.mergeWillAndAffiliateEntry(
      willQuery.data.entry,
      affiliateQuery.data.entry
    );
    cookies.set('affiliate', affiliate || vanitySlug);

    const overrideBySaleBanner =
      affiliateQuery.data.entry?.banner?.[0]?.bannerEnabled &&
      affiliateQuery.data.entry?.banner?.[0]?.overrideCharityBanner;
    if (!overrideBySaleBanner) {
      this.setBannerLogo(affiliateQuery.data.entry.bannerLogo);
      this.setBannerName(affiliateQuery.data.entry.name);
      this.setBannerText(affiliateQuery.data.entry.bannerText);
    }

    this.setTheme(affiliateQuery.data.entry.themeColours);
    this.setAffiliate(affiliateQuery.data.entry);
    this.setAppQuery(affiliateQuery.data.entry.appQueryParams);
  },
  methods: {
    ...mapActions(['setAppQuery', 'setAffiliate']),
    mergeWillAndAffiliateEntry(will, affiliate) {
      const entry = { ...affiliate };
      const fieldKeys = [
        'banner',
        'hero',
        'valuePropositions',
        'socialReviews',
        'features',
        'callToAction',
        'legal',
        'pricing',
        'articles',
        'faqs',
        'support',
      ];

      for (const fieldKey of fieldKeys) {
        if (
          affiliate[fieldKey][0]?.enabled &&
          affiliate[fieldKey][0]?.utiliseWillsEntryContent &&
          (will[fieldKey][0]?.enabled || will[fieldKey][0]?.bannerEnabled)
        ) {
          entry[fieldKey] = will[fieldKey];
        }
      }
      entry.mediaTrust = affiliate.mediaTrust[0].enabled;
      return entry;
    },
  },
};
