
import KeenSlider from 'keen-slider';

export default {
  name: 'MktBlogGuideSlider',
  props: {
    items: {
      required: true,
      type: Array,
    },
  },
  data() {
    return {
      keen: null,
    };
  },
  mounted() {
    this.keen = new KeenSlider(this.$refs.keen, {
      breakpoints: {
        '(min-width: 768px)': {
          slidesPerView: 2.1,
          spacing: 32,
          centered: false,
        },
      },
      slidesPerView: 1.1,
      spacing: 16,
      duration: 750,
      mode: 'free-snap',
    });
  },
};
