
import { mapActions } from 'vuex';
import GET_WILL_QUERY from '~/graphql/queries/GetWill';
import { craft } from '~/mixins';

export default {
  name: 'PageWelcomeBack',
  mixins: [
    craft({
      query: GET_WILL_QUERY,
      isLocalizable: true,
    }),
  ],
  data() {
    return {
      entry: null,
    };
  },
  mounted() {
    this.setIsReturning(true);
  },
  methods: {
    ...mapActions(['setIsReturning']),
  },
};
