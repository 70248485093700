
import { mapGetters } from 'vuex';

export default {
  name: 'MktWillFeatureGraphic',
  props: {
    asset: {
      required: true,
      type: Object,
    },
    type: {
      required: true,
      type: String,
      validate(type) {
        return ['charity', 'gift', 'guardians', 'pets'].includes(type);
      },
    },
  },
  computed: {
    ...mapGetters(['partnership']),
    cardClasses() {
      return {
        'w-4/5 lg:w-2/3 -mt-8 md:-mt-12 lg:mt-auto lg:ml-auto lg:mr-4 xl:mr-1 mb-auto lg:mb-10 xl:mb-32':
          this.isCharity && !this.hasOverride('charities'),
        'w-1/2 lg:w-2/5 xl:w-1/2 -mt-8 md:-mt-12 lg:mt-0 lg:ml-40 xl:ml-64 mb-auto lg:mb-0 lg:mt-16 xl:mt-24':
          this.isCharity && this.hasOverride('charities'),
        'w-4/5 lg:w-2/3 -mt-8 md:-mt-12 lg:mt-0 mb-auto lg:mb-0 lg:mr-auto':
          this.isGift,
        'w-1/2 lg:w-2/5 xl:w-1/2 -mt-8 md:-mt-12 lg:mt-0 mb-auto lg:mb-0 lg:mt-16 xl:mt-24':
          this.isGuardians,
        'md:w-11/12 -mx-2 md:mx-0 -mt-12 md:-mt-16 lg:mt-4 xl:mt-8 mb-auto':
          this.isPets,
      };
    },
    classes() {
      return {
        'lg:pb-20': this.isCharity,
        'xl:pr-20': this.isCharity && !this.partnership,
        'lg:pl-16 xl:pl-24 2xl:pl-40': this.isGift,
        'lg:pb-12 xl:pb-20': this.isGuardians,
        'lg:pt-12 xl:pt-20': this.isPets,
      };
    },
    fit() {
      return this.type === 'gift' ? 'height' : 'width';
    },
    imageClasses() {
      return {
        'lg:rounded-t-none': this.isCharity,
        'lg:rounded-br-none': this.isCharity && this.partnership,
        'lg:rounded-none': this.isGift,
        'lg:rounded-t-none lg:rounded-r-none': this.isGuardians,
        'lg:rounded-b-none lg:rounded-r-none': this.isPets,
      };
    },
    isCharity() {
      return this.type === 'charity';
    },
    isGift() {
      return this.type === 'gift';
    },
    isGuardians() {
      return this.type === 'guardians';
    },
    isPets() {
      return this.type === 'pets';
    },
  },
  methods: {
    hasOverride(type) {
      return this.partnership && type === this.partnership.willFeatureOverride;
    },
  },
};
