
import anime from 'animejs';

export default {
  name: 'MktExpandingWrapper',
  props: {
    open: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      isMounted: false,
    };
  },
  computed: {
    classes() {
      return {
        'h-0': !this.isMounted,
      };
    },
  },
  watch: {
    open(open) {
      if (open) {
        const height = anime.get(this.$refs.container, 'height', 'px');

        anime({
          targets: this.$el,
          height: [0, height],
          translateZ: 0,
          duration: 500,
          easing: 'cubicBezier(0.4, 0, 0.2, 1)',
          complete: () => {
            this.$el.style.height = null;
          },
        });
      } else {
        anime({
          targets: this.$el,
          height: 0,
          translateZ: 0,
          duration: 500,
          easing: 'cubicBezier(0.4, 0, 0.2, 1)',
        });
      }
    },
  },
  mounted() {
    if (!this.open) {
      anime.set(this.$el, { height: 0 });
    }

    this.isMounted = true;
  },
};
