
export default {
  name: 'MktBaseTextBlock',
  props: {
    body: {
      default: '',
      type: String,
    },
    heading: {
      default: '',
      type: String,
    },
  },
};
