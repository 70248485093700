
export default {
  name: 'RuSection',
  props: {
    rounded: {
      type: Boolean,
      default: false,
    },
    wide: {
      type: Boolean,
      default: false,
    },
    background: {
      type: String,
      default: null,
    },
    color: {
      type: String,
      default: null,
    },
  },
  computed: {
    backgroundColor() {
      if (!this.background) {
        return null;
      } else if (this.background.startsWith('#')) {
        return this.background;
      } else {
        return `rgba(var(--${this.background}), 1)`;
      }
    },
    textColor() {
      if (!this.color) {
        return null;
      } else if (this.color.startsWith('#')) {
        return this.color;
      } else {
        return `rgba(var(--${this.color}), 1)`;
      }
    },
    styles() {
      return {
        '--background-color': this.backgroundColor,
        '--text-color': this.textColor,
      };
    },
  },
};
