
import VDismiss from 'vue-dismiss';

export default {
  name: 'MktAdiDropdown',
  mixins: [VDismiss],
  props: {
    direction: {
      default: 'down',
      type: String,
      validator(value) {
        return ['up', 'down'].includes(value);
      },
    },
  },
  data() {
    return {
      active: false,
    };
  },
  computed: {
    classes() {
      return {
        'bottom-100 mb-4': this.direction === 'up',
        'opacity-0 pointer-events-none translate-y-negative-1':
          !this.active && this.direction === 'down',
        'opacity-0 pointer-events-none translate-y-positive-1':
          !this.active && this.direction === 'up',
      };
    },
  },
  methods: {
    dismiss() {
      this.active = false;
    },
    toggle() {
      this.active = !this.active;
    },
  },
};
