import { render, staticRenderFns } from "./WillsFeatures.vue?vue&type=template&id=645f04aa"
import script from "./WillsFeatures.vue?vue&type=script&lang=js"
export * from "./WillsFeatures.vue?vue&type=script&lang=js"
import style0 from "./WillsFeatures.vue?vue&type=style&index=0&id=645f04aa&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {RuHeadline: require('/home/runner/work/ruckus/ruckus/packages/ruach/atoms/Headline/index.vue').default,MktWillsFeaturesPrepare: require('/home/runner/work/ruckus/ruckus/apps/safewill.com/components/snowflakes/wills/features/Prepare.vue').default,MktWillsFeaturesSubmit: require('/home/runner/work/ruckus/ruckus/apps/safewill.com/components/snowflakes/wills/features/Submit.vue').default,MktWillsFeaturesPrint: require('/home/runner/work/ruckus/ruckus/apps/safewill.com/components/snowflakes/wills/features/Print.vue').default,MktGetStarted: require('/home/runner/work/ruckus/ruckus/apps/safewill.com/components/organisms/GetStarted.vue').default,RuIcon: require('/home/runner/work/ruckus/ruckus/packages/ruach/atoms/Icon/index.vue').default,RuBox: require('/home/runner/work/ruckus/ruckus/packages/ruach/atoms/Box/index.vue').default})
