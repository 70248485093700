
import reviewsData from '~/google-reviews.json';

export default {
  name: 'MktGoogleReviewSummary',
  props: {},
  computed: {
    averageRating() {
      return reviewsData.averageRating
        ? parseFloat(reviewsData.averageRating).toFixed(1)
        : '4.8';
    },
    totalReviews() {
      return reviewsData.totalReviews ?? '1514';
    },
  },
  methods: {},
};
