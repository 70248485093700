import { render, staticRenderFns } from "./index.vue?vue&type=template&id=1561853d"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MktLogoBubbles: require('/home/runner/work/ruckus/ruckus/apps/safewill.com/components/LogoBubbles.vue').default,MktMarkedText: require('/home/runner/work/ruckus/ruckus/apps/safewill.com/components/MarkedText.vue').default,MktCharitiesContactForm: require('/home/runner/work/ruckus/ruckus/apps/safewill.com/components/CharitiesContactForm.vue').default,MktTextMarker: require('/home/runner/work/ruckus/ruckus/apps/safewill.com/components/TextMarker.vue').default,MktSectionHeading: require('/home/runner/work/ruckus/ruckus/apps/safewill.com/components/SectionHeading.vue').default,MktCharitiesGraphic: require('/home/runner/work/ruckus/ruckus/apps/safewill.com/components/CharitiesGraphic.vue').default,MktBaseTextBlock: require('/home/runner/work/ruckus/ruckus/apps/safewill.com/components/BaseTextBlock.vue').default,MktVideoCard: require('/home/runner/work/ruckus/ruckus/apps/safewill.com/components/VideoCard.vue').default,MktBaseHeader: require('/home/runner/work/ruckus/ruckus/apps/safewill.com/components/BaseHeader.vue').default,MktSocialReviews: require('/home/runner/work/ruckus/ruckus/apps/safewill.com/components/organisms/SocialReviews.vue').default,RuSection: require('/home/runner/work/ruckus/ruckus/packages/ruach/atoms/Section/index.vue').default,MktVideoModal: require('/home/runner/work/ruckus/ruckus/apps/safewill.com/components/VideoModal.vue').default})
