import { render, staticRenderFns } from "./PromoBanner.vue?vue&type=template&id=3ba99b45"
import script from "./PromoBanner.vue?vue&type=script&lang=js"
export * from "./PromoBanner.vue?vue&type=script&lang=js"
import style0 from "./PromoBanner.vue?vue&type=style&index=0&id=3ba99b45&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MktBaseAsset: require('/home/runner/work/ruckus/ruckus/apps/safewill.com/components/BaseAsset.vue').default,RuIcon: require('/home/runner/work/ruckus/ruckus/packages/ruach/atoms/Icon/index.vue').default,MktOnboardingButton: require('/home/runner/work/ruckus/ruckus/apps/safewill.com/components/OnboardingButton.vue').default})
