
export default {
  name: 'MktLoadingSpinner',
  props: {
    color: {
      default: '#000000',
      type: String,
    },
    size: {
      default: 20,
      type: Number,
    },
    strokeWidth: {
      default: 2,
      type: Number,
    },
  },
  computed: {
    x() {
      return this.size - this.strokeWidth;
    },
    y() {
      return this.x / 2;
    },
    d() {
      return `M${this.x} ${this.y}c0-${(this.y * 9.94) / 18}-${
        (this.y * 8.06) / 18
      }-${this.y}-${this.y}-${this.y}`;
    },
  },
};
