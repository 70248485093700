
import { mapGetters } from 'vuex';

export default {
  name: 'MktOnboardingSection',
  computed: {
    ...mapGetters(['brandLogos', 'partnership']),
    ...mapGetters('globals', ['onboardingSection']),
    filteredLogos() {
      return this.brandLogos.filter((logo) => !['vogue'].includes(logo));
    },
  },
};
