
import { mapGetters } from 'vuex';

import GET_BLOG_INDEX_QUERY from '~/graphql/queries/GetBlogIndex';
import { craft, transition } from '~/mixins';

export default {
  name: 'PageBlogIndex',
  mixins: [
    craft({
      query: GET_BLOG_INDEX_QUERY,
      isLocalizable: true,
      transform({
        blogPosts,
        blogTopics,
        page: { featuredAuthors, featuredGuides, featuredPost, trendingPosts },
      }) {
        const visibleTrendingPosts = trendingPosts.filter(
          (post) => !post.isHidden
        );
        const visibleFeaturedPost = featuredPost.filter(
          (post) => !post.isHidden
        );

        return {
          blogPosts,
          blogTopics,
          featuredAuthors,
          featuredGuides,
          featuredPost: visibleFeaturedPost[0],
          trendingPosts: visibleTrendingPosts,
        };
      },
    }),
    transition,
  ],
  data() {
    return {
      blogPosts: [],
      blogTopics: [],
      featuredAuthors: [],
      featuredGuides: [],
      featuredPost: null,
      trendingPosts: [],
    };
  },
  computed: {
    ...mapGetters(['hasMobileLayout']),
    trimmedBlogPosts() {
      let posts;

      switch (this.$mq) {
        case 'default':
        case 'sm':
        case 'md':
          posts = this.blogPosts.slice(0, 8);
          break;
        default:
          posts = this.blogPosts;
          break;
      }

      return posts;
    },
  },
};
