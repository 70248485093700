
import anime from 'animejs';

export default {
  name: 'MktFaqsItem',
  props: {
    active: {
      default: false,
      type: Boolean,
    },
    answer: {
      required: false,
      type: String,
      default: '',
    },
    question: {
      required: true,
      type: String,
    },
  },
  watch: {
    active(active) {
      const anim = anime.timeline({
        duration: 350,
        easing: 'easeInOutQuad',
      });

      if (active) {
        this.$emit('active');
        anim.add({
          targets: [this.$refs.pathA, this.$refs.pathB],
          rotate: 45,
        });
      } else {
        anim.add({
          targets: [this.$refs.pathA, this.$refs.pathB],
          rotate: 0,
        });
      }
    },
  },
};
