
import { mapGetters } from 'vuex';

import GET_HOW_IT_WORKS_QUERY from '~/graphql/queries/GetHowItWorks';
import { craft, transition, partnerships } from '~/mixins';
import { collapseFieldArray } from '~/util';

export default {
  name: 'PageHowItWorks',
  mixins: [
    craft({
      query: GET_HOW_IT_WORKS_QUERY,
      isLocalizable: true,
      transform({ entry }) {
        entry.headerContent = collapseFieldArray(entry.headerContent);
        entry.updates = collapseFieldArray(entry.updates);

        return entry;
      },
    }),
    transition,
    partnerships,
  ],
  data() {
    return {
      headerContent: { heading: '', body: '' },
      steps: [],
      updates: [],
    };
  },
  computed: {
    ...mapGetters(['hasMobileLayout']),
    ...mapGetters('globals', ['video', 'videoThumbnail']),
  },
};
