
export default {
  name: 'MktArrowButton',
  props: {
    direction: {
      default: null,
      type: String,
      validate(direction) {
        return ['<', '>'].includes(direction);
      },
    },
  },
  computed: {
    ariaLabel() {
      return this.direction === '<' ? 'previous' : 'next';
    },
    pathA() {
      return this.direction === '<' ? 'M23 11H3' : 'M0 11h20';
    },
    pathB() {
      return this.direction === '<' ? 'M11 3l-8 8 8 8' : 'M12 3l8 8-8 8';
    },
  },
};
