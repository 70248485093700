
import anime from 'animejs';
import { mapGetters } from 'vuex';

export default {
  name: 'MktFooter',
  computed: {
    ...mapGetters('globals', [
      'address',
      'disclaimer',
      'phoneNumber',
      'socialChannels',
    ]),
    currentYear() {
      return new Date().getFullYear();
    },
  },
  methods: {
    enter(targets, complete) {
      anime({
        targets,
        opacity: [0, 1],
        duration: 400,
        easing: 'easeInOutQuad',
        complete,
      });
    },
    leave(targets, complete) {
      anime({
        targets,
        opacity: [1, 0],
        duration: 400,
        easing: 'easeInOutQuad',
        complete,
      });
    },
  },
};
