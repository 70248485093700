
import { mapActions, mapGetters } from 'vuex';
import { PortalTarget } from 'portal-vue';
import cookies from 'js-cookie';

import layout from '~/mixins/layout';
import utm from '~/mixins/utm';

export default {
  name: 'LayoutDefault',
  components: {
    PortalTarget,
  },
  mixins: [layout, utm],
  data() {
    return {
      fontsLoaded: false,
      showCountrySelectionBanner: false,
    };
  },
  computed: {
    ...mapGetters(['theme', 'hasPartnerCookie', 'banner', 'affiliate']),
  },
  watch: {
    $route(_to, from) {
      if (
        this.affiliate &&
        from.fullPath.endsWith(this.affiliate.affiliateSlug)
      ) {
        this.setBannerLogo(null);
        this.setBannerName('');
        this.setBannerText('');
        this.setTheme({
          background: null,
          logoBackground: null,
          text: null,
          textActive: null,
        });
      }
    },
  },
  mounted() {
    this.setHasPartnerCookie(!!cookies.get('partner'));
    this.checkAndShowCountrySelection();
  },
  methods: {
    ...mapActions(['setHasPartnerCookie', 'setTheme']),
    ...mapActions('banner', [
      'setBannerName',
      'setBannerLogo',
      'setBannerText',
    ]),
    checkAndShowCountrySelection() {
      const locale = this.$i18n.locale;
      const detectedTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const isNZTimezone = detectedTimezone.startsWith('Pacific/Auckland');
      const isAUTimezone = detectedTimezone.startsWith('Australia/');

      if (
        (isNZTimezone && locale !== 'nz') ||
        (isAUTimezone && locale !== 'au')
      ) {
        this.showCountrySelectionBanner = true;
      }
    },
    closeCountrySelectionBanner() {
      this.showCountrySelectionBanner = false;
    },
  },
};
