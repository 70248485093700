
import { mapGetters } from 'vuex';

export default {
  name: 'PowerOfAttorney',
  data() {
    return {
      headerContent: {
        heading: 'Appoint your Powers of <em>Attorney</em>',
        body: this.$t('pages.powerOfAttorney.description'),
        image: {
          title: 'Home header',
          filename: 'power-of-attorney__couple.jpg',
          kind: 'image',
          original:
            'https://storage.googleapis.com/cms-safewill-operation-australia-southeast1/images/power-of-attorney__couple.jpg',
          xs: 'https://storage.googleapis.com/cms-safewill-operation-australia-southeast1/images/power-of-attorney__couple.jpg',
          sm: 'https://storage.googleapis.com/cms-safewill-operation-australia-southeast1/images/power-of-attorney__couple.jpg',
          md: 'https://storage.googleapis.com/cms-safewill-operation-australia-southeast1/images/power-of-attorney__couple.jpg',
          lg: 'https://storage.googleapis.com/cms-safewill-operation-australia-southeast1/images/power-of-attorney__couple.jpg',
          xl: 'https://storage.googleapis.com/cms-safewill-operation-australia-southeast1/images/power-of-attorney__couple.jpg',
          __typename: 'gcs_Asset',
        },
      },
      questions: {
        au: [
          {
            question: `What is an Enduring Power of Attorney?`,
            answer: `<p class="mb-4">
            Enduring Powers of Attorney are legal documents that allow you to
            appoint someone to make decisions on your behalf if you are unable
            to do so yourself. There are two types of Enduring Powers of Attorney
            you can make on the Safewill Platform:
            </p>
            <ul class="list-disc pl-6 text-lg">
              <li class="mb-4">
                <strong>Financial Powers of Attorney</strong> allow you to choose
                someone to manage your financial and legal affairs, which includes
                buying and selling real estate, shares and other assets, operating
                your bank accounts and spending money on your behalf.
              </li>
              <li class="mb-4">
                <strong>Medical Powers of Attorney</strong> allow you to choose
                someone to manage your health and lifestyle matters, including the
                type of medical care you receive, or the nature of your living
                arrangements. Depending on the State you live in, this medical Power
                of Attorney document is commonly known as either an ‘Enduring
                Guardianship', ‘Advance Personal Plan’ or ‘Advance Care Directive’.
              </li>
            </ul>
            <p class="mb-4">
              Your appointed decision maker(s) will be subject to a number of legal
              obligations and duties, including to always act in your best interests
              and in accordance with your written instructions.
            </p>`,
          },
          {
            question: `What is the difference between a General and Enduring Power of Attorney?`,
            answer: `<p class="mb-4">
            A General Power of Attorney operates from the date of signing and becomes invalid
            when you lose the mental capacity to make your own decisions. In contrast, an
            Enduring Power of Attorney written on the Safewill platform only comes into effect
            if you lose capacity to make decisions for yourself.
            </p>`,
          },
          {
            question: ` When will my Powers of Attorney take effect?`,
            answer: `<p class="mb-4">
              At Safewill, the Powers of Attorney you create will only come into
              effect if you lose your decision-making capacity.
            </p>
            <p class="mb-4">
              The authority given under the Power of Attorney will continue
              throughout the time you lack decision-making capacity, and will come
              to an end when you pass away.
            </p>`,
          },
          {
            question: ` Do I need witnesses when signing my POA?`,
            answer: `<p class="mb-4">
            Yes, all POAs in Australia need to be signed in the presence of witnesses.
            The requirements of who constitutes a valid witness will vary from state to state,
            although generally includes a Justice of the Peace, or in the case of NSW,
            requires a solicitor. Please take this into account when considering the finalisation of your POA.
            </p>`,
          },
          {
            question: ` What’s the difference between this and my Will?`,
            answer: `<p class="mb-4">
              Your Will and your Powers of Attorney function at different times to
              carry out and safeguard your wishes.
            </p>
            <p class="mb-4">
              Powers of Attorney come into effect while you are still alive but have
              lost the ability to make decisions for yourself.
              <strong>
                The effect of the Power of Attorney ends when you pass away.
              </strong>
            </p>
            <p class="mb-4">
              Your Will, on the other hand,
              <strong>only comes into effect once you pass away</strong>. Under your
              Will, you appoint an Executor to carry out the wishes in your Will,
              and manage the transfer of your assets to the required beneficiaries
              upon your death.
            </p>`,
          },
          {
            question: `Do I need Enduring Powers of Attorney?`,
            answer: `<p class="mb-4">
              People tend to assume that because their loved ones know what their
              wishes would be in certain circumstances, those same people would have
              the ability to make decisions on their behalf. However, without a
              valid Power of Attorney appointment in place, this is unlikely to be
              the case.
            </p>
            <p class="mb-4">
              Completing a Power of Attorney document provides certainty that
              someone you trust is able to help manage your affairs and make
              decisions on your behalf when it matters most.
            </p>
            <p class="mb-4">
              For financial matters, this means your chosen attorney can readily
              access your solely held assets, such as bank accounts that may be
              required to pay for medical treatment or supporting your dependents.
              For health matters, a medical Power of Attorney will allow you to
              appoint someone to speak with doctors and nurses on your behalf about
              the types of treatment you receive. In some states you can also give
              your decision maker the ability to make decisions about your personal
              matters, such as who may visit you, whether you prefer a specific diet
              (e.g. vegetarian), and which long-term care facility you move to (if
              necessary).
            </p>`,
          },
          {
            question: `What happens if I don’t have Enduring Powers of Attorney?`,
            answer: `<p class="mb-4">
              If there is no valid Power of Attorney and you lose capacity, your
              loved ones may have to go through alternative, lengthy Court or
              Tribunal processes to be legally appointed as your decision maker.
              This means that even if the right person is eventually granted
              decision making power, there may be significant delay between you
              losing the ability to make decisions and your decision maker being
              appointed. Often this can result in bills going unpaid, dependents not
              being cared for, and medical decisions being made that may not be what
              you would have wanted.
            </p>
            <p class="mb-4">
              There is also a chance that a Court or Tribunal will not appoint the
              person you would have otherwise chosen to make these decisions for
              you, and an alternative decision maker is appointed. In all these
              cases, it is much better to have certainty over who will be appointed,
              and grant them the necessary powers to quickly make decisions if the
              need ever arose.
            </p>`,
          },
          {
            question: `What do I need to get started?`,
            answer: `<p class="mb-4">
              You’ll just need 10 minutes and an idea of who you’d trust with
              decisions around your finances or healthcare. We’ll guide you through
              the rest.
            </p>`,
          },
          {
            question: `How much does it normally cost?`,
            answer: `<p class="mb-4">
              With a lawyer, Power of Attorney documents can cost hundreds, if not
              thousands of dollars. It can also be a lengthy and difficult process
              to go through. You can access your medical and financial Power of
              Attorney documents at a price of just $99.
            </p>`,
          },
          {
            question: `How long will it take?`,
            answer: `<p class="mb-4">
              With Safewill, completing your Power of Attorney can take as little as
              10 minutes.
            </p>`,
          },
          {
            question: ` What powers will my decision makers legally have?`,
            answer: `<p class="mb-4">
              The details are slightly different in each state, but in all states
              the principle is the same and your chosen decision makers have the
              power to make decisions on your behalf. Remember that in all cases,
              they are legally bound to act in your best interests. Even so, you
              should make sure to appoint people that you trust completely to fill
              the role.
            </p>`,
          },
          {
            question: ` Can I place any limitations or exclusions on their power?`,
            answer: `<p class="mb-4">
                Yes, it’s up to you. We’ll ask you if there are any specific
                limitations or exclusions you want to include in your Powers of
                Attorney for your decision makers.
              </p>`,
          },
          {
            question: ` What if I’ve previously created Powers of Attorney?`,
            answer: `<p class="mb-4">
              The rules are different in each state. In Victoria, creating a new
              Powers of Attorney document will override any previous documents. In
              the remaining states, creating a new document will not automatically
              override any previous documents — if you’d like those previous
              documents to be terminated, you’ll need to revoke your previous
              documents separately.
            </p>`,
          },
        ],
        nz: [
          {
            question: `What is an Enduring Power of Attorney?`,
            answer: `<p class="mb-4">
            Enduring Powers of Attorney (EPA) are legal documents that allow you
            to appoint someone to make decisions on your behalf if you are unable
            to do so yourself. There are two types of Enduring Powers of Attorney
            you can make on the Safewill Platform:
            </p>
            <ul class="list-disc pl-6 text-lg">
              <li class="mb-4">
                <strong>EPA for Property</strong> allows you to choose someone to
                manage your financial affairs, which includes buying and selling
                real estate, shares and other assets, operating your bank accounts
                and spending money on your behalf.
              </li>
              <li class="mb-4">
                <strong>EPA for Personal Care and Welfare</strong> allows you to
                choose someone to manage your health and lifestyle matters, including
                the type of medical care you receive, or the nature of your
                living arrangements.
              </li>
            </ul>
            <p class="mb-4">
              Your appointed attorney(s) will be subject to a number of legal
              obligations and duties, including to always act in your best interests
              and in accordance with your written instructions.
            </p>`,
          },
          {
            question: `What is the difference between an Ordinary and Enduring Power of Attorney?`,
            answer: `<p class="mb-4">
            An Ordinary Power of Attorney operates from the date of signing and
            becomes invalid when you lose the mental capacity to make your own
            decisions. In contrast, an Enduring Power of Attorney written on the
            Safewill platform only comes into effect if you lose capacity to make
            decisions for yourself.
            </p>`,
          },
          {
            question: ` When will my Powers of Attorney take effect?`,
            answer: `<p class="mb-4">
              At Safewill, the Powers of Attorney you create will only come into
              effect if you lose your decision-making capacity.
            </p>
            <p class="mb-4">
              The authority given under the Power of Attorney will continue
              throughout the time you lack decision-making capacity, and will come
              to an end when you pass away.
            </p>`,
          },
          {
            question: ` Do I need witnesses when signing my Power of Attorney?`,
            answer: `<p class="mb-4">
            Yes, it is a requirements for your Enduring Powers of Attorney documents
            to be witnessed by an authorised witness such as a lawyer. The same
            authorised witness must also sign a certificate to accompany the EPA documents.
            Please take this into account when considering the finalisation of
            your Power of Attorney.
            </p>`,
          },
          {
            question: ` What’s the difference between this and my Will?`,
            answer: `<p class="mb-4">
              Your Will and your Powers of Attorney function at different times to
              carry out and safeguard your wishes.
            </p>
            <p class="mb-4">
              Powers of Attorney come into effect while you are still alive but have
              lost the ability to make decisions for yourself.
              <strong>
                The effect of the Power of Attorney ends when you pass away.
              </strong>
            </p>
            <p class="mb-4">
              Your Will, on the other hand,
              <strong>only comes into effect once you pass away</strong>. Under your
              Will, you appoint an Executor to carry out the wishes in your Will,
              and manage the transfer of your assets to the required beneficiaries
              upon your death.
            </p>`,
          },
          {
            question: `Do I need Enduring Powers of Attorney?`,
            answer: `<p class="mb-4">
              People tend to assume that because their loved ones know what their
              wishes would be in certain circumstances, those same people would
              have the ability to make decisions on their behalf. However, without
              a valid Power of Attorney appointment in place, this is unlikely to
              be the case.
            </p>
            <p class="mb-4">
              Completing a Power of Attorney document provides certainty that
              someone you trust is able to help manage your affairs and make
              decisions on your behalf when it matters most.
            </p>
            <p class="mb-4">
              For financial matters, this means your chosen attorney can readily
              access your solely held assets, such as bank accounts that may be
              required to pay for medical treatment or supporting your dependents.
              For health matters, a medical Power of Attorney will allow you to
              appoint someone to speak with doctors and nurses on your behalf about
              the types of treatment you receive.
            </p>`,
          },
          {
            question: `What happens if I don’t have Enduring Powers of Attorney?`,
            answer: `<p class="mb-4">
              If there is no valid Power of Attorney and you lose capacity, your
              loved ones may have to apply to the Family Court to be legally
              appointed as your decision maker. This means that even if the right
              person is eventually granted decision making power, there may be
              significant delay between you losing the ability to make decisions
              and your decision maker being appointed. Often this can result in
              bills going unpaid, dependents not being cared for, and medical decisions
              being made that may not be what you would have wanted.
            </p>
            <p class="mb-4">
              There is also a chance that the Court will not appoint the person
              you would have otherwise chosen to make these decisions for you,
              and an alternative decision maker is appointed. In all these cases,
              it is much better to have certainty over who will be appointed, and
              grant them the necessary powers to quickly make decisions if the
              need ever arose.
            </p>`,
          },
          {
            question: `What do I need to get started?`,
            answer: `<p class="mb-4">
              You’ll just need 10 minutes and an idea of who you’d trust with
              decisions around your finances or healthcare. We’ll guide you through
              the rest.
            </p>`,
          },
          {
            question: `How much does it normally cost?`,
            answer: `<p class="mb-4">
              With a lawyer, Power of Attorney documents can cost hundreds, if not
              thousands of dollars. It can also be a lengthy and difficult process
              to go through. You can access your medical and financial Power of
              Attorney documents at a price of just $99.
            </p>`,
          },
          {
            question: `How long will it take?`,
            answer: `<p class="mb-4">
              With Safewill, completing your Power of Attorney can take as little as
              10 minutes.
            </p>`,
          },
          {
            question: ` Can I place any limitations or exclusions on their power?`,
            answer: `<p class="mb-4">
                Yes, it’s up to you. We’ll ask you if there are any specific
                limitations or exclusions you want to include in your Powers of
                Attorney for your decision makers.
              </p>`,
          },
          {
            question: ` What if I’ve previously created Powers of Attorney?`,
            answer: `<p class="mb-4">
              You can change or revoke your EPA at any time so long as you have
              mental capacity.
              <br>
              Changes to an EPA should be done in writing, in a document that’s
              signed and witnessed in the same way that the original EPA was witnessed.
              <br>
              You can cancel the EPA simply by giving your attorney written notice.
              You can also suspend the EPA temporarily if you were “mentally
              incapable” for a time but have now regained “mental capacity”.
            </p>`,
          },
        ],
      },
    };
  },
  head() {
    return {
      title: 'Power Of Attorney | Plan your Estate wishes Online | Safewill',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content:
            'Easily appoint your Powers of Attorney today with Safewill.',
        },
      ],
    };
  },
  computed: {
    ...mapGetters(['appQuery']),
    poaLink() {
      return `${this.$config.safewillAppUrl}${this.localePath(
        `/get-started?redirect=/poa`
      )}`;
    },
  },
};
