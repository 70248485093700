
import GET_TERMS_QUERY from '~/graphql/queries/GetTerms';

import { craft, transition, partnerships } from '~/mixins';

export default {
  name: 'PageTermsOfUse',
  mixins: [
    craft({
      query: GET_TERMS_QUERY,
      isLocalizable: true,
    }),
    transition,
    partnerships,
  ],
  data() {
    return {
      entry: null,
    };
  },
};
