
export default {
  name: 'MktBaseAsset',
  props: {
    asset: {
      default: null,
      type: Object,
    },
    border: {
      default: false,
      type: Boolean,
    },
    crop: {
      default: false,
      type: Boolean,
    },
    fit: {
      default: 'width',
      type: String,
    },
    rounded: {
      default: false,
      type: Boolean,
    },
    sizes: {
      default: '100vw',
      type: String,
    },
    inset: {
      default: false,
      type: Boolean,
    },
  },
  computed: {
    assetClasses() {
      return {
        'absolute md:inset-0 flex items-center': this.crop,
        'inset-0': this.inset,
      };
    },
    wrapperClasses() {
      return {
        'border border-black border-opacity-10': this.border,
        'aspect-wide': this.crop,
        'rounded-xl': this.rounded,
        'w-full h-full': ['contain', 'cover', 'none'].includes(this.fit),
        'w-full': this.fit === 'width',
        'h-full': this.fit === 'height',
      };
    },
    srcset() {
      return this.asset.kind === 'image'
        ? `${this.asset.xs} 320w, ${this.asset.sm} 640w, ${this.asset.md} 960w, ${this.asset.lg} 1200w, ${this.asset.xl} 1600w`
        : null;
    },
  },
};
