
import { mapActions, mapGetters } from 'vuex';

import GET_PRICING_QUERY from '~/graphql/queries/GetPricing';
import { craft, transition, partnerships } from '~/mixins';
import { collapseFieldArray, queryStringToObject } from '~/util';

export default {
  name: 'PagePricing',
  mixins: [
    craft({
      query: GET_PRICING_QUERY,
      isLocalizable: true,
      transform({ entry }) {
        entry.headerContent = collapseFieldArray(entry.headerContent);
        entry.features = collapseFieldArray(entry.features);

        return entry;
      },
    }),
    transition,
    partnerships,
  ],
  data() {
    return {
      couplesPricing: 0,
      headerContent: { heading: '', body: '' },
      individualPricing: 0,
      features: { heading: '', items: [] },
      pricingToggle: false,
      testimonials: [],
    };
  },
  computed: {
    currentPricing() {
      return this.pricingToggle ? this.couplesPricing : this.individualPricing;
    },
    currentQuantity() {
      return this.pricingToggle
        ? this.$t('common.text.forTwoWills')
        : this.$t('common.text.currency');
    },
    afterpayPrice() {
      return (this.currentPricing / 4).toFixed(2);
    },
    ...mapGetters(['appQuery']),
  },
  watch: {
    pricingToggle(isCouple) {
      const query = {
        ...(this.appQuery && queryStringToObject(this.appQuery)),
        hasPartner: true,
      };
      if (!isCouple) {
        delete query.hasPartner;
      }
      this.setAppQuery(query);
    },
  },
  methods: {
    ...mapActions(['setAppQuery']),
  },
};
