
export default {
  name: 'MktTeamMember',
  props: {
    /* eslint-disable-next-line vue/prop-name-casing */
    __typename: {
      required: true,
      type: String,
    },
    name: {
      required: true,
      type: String,
    },
    portrait: {
      default: null,
      type: Array,
    },
    role: {
      required: true,
      type: String,
    },
  },
};
