
import { mapGetters } from 'vuex';
export default {
  name: 'MktSupport',
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ...mapGetters('globals', ['phoneNumber']),
  },
};
