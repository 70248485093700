
export default {
  name: 'MktHomeFeatures',
  props: {
    data: {
      type: Object,
      default: null,
    },
  },
};
