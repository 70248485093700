
export default {
  name: 'MktBlogItemGuide',
  props: {
    /* eslint-disable-next-line vue/prop-name-casing */
    __typename: {
      required: true,
      type: String,
    },
    author: {
      required: true,
      type: Object,
    },
    date: {
      required: true,
      type: String,
    },
    headerImage: {
      required: true,
      type: Array,
    },
    id: {
      required: true,
      type: String,
    },
    slug: {
      required: true,
      type: String,
    },
    summary: {
      required: true,
      type: String,
    },
    title: {
      required: true,
      type: String,
    },
    uri: {
      required: true,
      type: String,
    },
  },
};
