
import GET_LEGAL_ADVICE_QUERY from '~/graphql/queries/GetLegalAdvice';
import { craft, home, transition, partnerships } from '~/mixins';
import { transformLegalAdviceData } from '~/util';

export default {
  name: 'PageLegalAdvice',
  mixins: [
    craft({
      query: GET_LEGAL_ADVICE_QUERY,
      isLocalizable: true,
      transform: transformLegalAdviceData,
    }),
    home,
    transition,
    partnerships,
  ],
  data() {
    return {
      entry: null,
    };
  },
};
