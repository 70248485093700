
import { mapGetters } from 'vuex';

export default {
  name: 'MktPartnerOffer',
  computed: {
    ...mapGetters([
      'appQuery',
      'partnership',
      'affiliate',
      'affiliateSlug',
      'theme',
    ]),
    partneredEntity() {
      return this.partnership ?? this.affiliate;
    },
    content() {
      return this.partneredEntity.willFeatureContent[0];
    },
    ctaLink() {
      const affiliateLink = this.affiliateSlug ? `/${this.affiliateSlug}` : '';
      return `${this.$config.safewillAppUrl}${affiliateLink}${this.localePath(
        `/get-started${this.appQuery}`
      )}`;
    },
  },
};
