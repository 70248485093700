
import striptags from 'striptags';
export default {
  name: 'MktSocialReviews',
  props: {
    data: {
      default: null,
      type: Object,
    },
  },
  computed: {
    reviewContent() {
      return this.data.reviews.map((review) =>
        striptags(review.reviewContent.replace(/&amp;/g, '&'))
      );
    },
  },
};
