
export default {
  name: 'MktBaseVideo',
  props: {
    src: {
      required: true,
      type: String,
    },
  },
};
