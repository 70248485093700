
import GET_PARTNERSHIPS_QUERY from '~/graphql/queries/GetPartnerships';

import { craft, transition, partnerships } from '~/mixins';

export default {
  name: 'CharityDirectory',
  mixins: [
    craft({
      query: GET_PARTNERSHIPS_QUERY,
      isLocalizable: true,
      transform({ entries }) {
        const entriesUnsorted = [];
        for (const i in entries) {
          if (
            // test charities
            !['swt-', '-123'].some((exclude) =>
              entries[i].slug.includes(exclude)
            ) &&
            // charity exclusives
            !entries[i].title.toLowerCase().includes('exclusive offer')
          ) {
            entriesUnsorted.push({
              title: entries[i].title,
              slug: entries[i].partnerships_vanityUrl,
            });
          }
        }
        const entriesSorted = [...entriesUnsorted].sort(function (a, b) {
          const entryA = a.title.toLowerCase();
          const entryB = b.title.toLowerCase();
          if (entryA < entryB) {
            return -1;
          } else if (entryA > entryB) {
            return 1;
          } else {
            return 0;
          }
        });
        const entriesByFirstLetter = [];
        const alphabet = [];
        for (const entryIndex in entriesSorted) {
          const entry = entriesSorted[entryIndex];
          const firstLetter = entry.title[0].toLocaleUpperCase();
          if (!alphabet.includes(firstLetter)) {
            entriesByFirstLetter.push({
              letter: firstLetter,
              entries: [entry],
            });
            alphabet.push(firstLetter);
          } else {
            entriesByFirstLetter[alphabet.length - 1].entries.push(entry);
          }
        }
        return { entriesByFirstLetter };
      },
    }),
    transition,
    partnerships,
  ],
  data() {
    return {
      entriesByFirstLetter: [],
      headerContent: {
        image: {
          title: 'Home header',
          filename: 'charities-directory-hero-image.png',
          kind: 'image',
          original:
            'https://storage.googleapis.com/cms-safewill-operation-australia-southeast1/images/charities-directory-hero-image.png',
          xs: 'https://storage.googleapis.com/cms-safewill-operation-australia-southeast1/images/charities-directory-hero-image.png',
          sm: 'https://storage.googleapis.com/cms-safewill-operation-australia-southeast1/images/charities-directory-hero-image.png',
          md: 'https://storage.googleapis.com/cms-safewill-operation-australia-southeast1/images/charities-directory-hero-image.png',
          lg: 'https://storage.googleapis.com/cms-safewill-operation-australia-southeast1/images/charities-directory-hero-image.png',
          xl: 'https://storage.googleapis.com/cms-safewill-operation-australia-southeast1/images/charities-directory-hero-image.png',
          __typename: 'gcs_Asset',
        },
      },
    };
  },
  head() {
    return {
      title:
        'Charities Directory | Australia and New Zealand Wills Online | Safewill',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content:
            'Be remembered how you want to be, and leave a bequest to charity in your Will. Discover a range of charities and foundations that partner with Safewill.',
        },
      ],
    };
  },
};
