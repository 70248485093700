
import { mapActions, mapGetters } from 'vuex';
import { isFreeWillWeek } from '~/util';

export default {
  name: 'MktPartnerOffer',
  computed: {
    ...mapGetters(['appQuery', 'partnership', 'affiliate', 'theme']),
    partneredEntity() {
      return this.partnership ?? this.affiliate;
    },
    intro() {
      const heading =
        isFreeWillWeek() && !!this.partneredEntity.fwwIntro[0]?.heading
          ? this.partneredEntity.fwwIntro[0].heading
          : this.partneredEntity.intro[0].heading;
      const body =
        isFreeWillWeek() && !!this.partneredEntity.fwwIntro[0]?.body
          ? this.partneredEntity.fwwIntro[0].body
          : this.partneredEntity.intro[0].body;
      return {
        ...this.partneredEntity.intro[0],
        heading,
        body,
      };
    },
    hasVideo() {
      return (
        !!this.partneredEntity.partnerVideo[0] &&
        !!this.partneredEntity.partnerVideoThumbnail[0]
      );
    },
    video() {
      return {
        file: this.partneredEntity.partnerVideo[0],
        thumbnail: this.partneredEntity.partnerVideoThumbnail[0],
      };
    },
  },
  methods: {
    ...mapActions(['toggleVideoModal']),
  },
};
