
export default {
  name: 'MktCharitiesContactForm',
  data() {
    return {
      email: '',
      hasError: false,
      isLoading: false,
      success: false,
    };
  },
  computed: {
    submitLabel() {
      return this.success ? 'Done!' : 'Sign Me Up!';
    },
    payload() {
      return {
        email: this.email,
      };
    },
  },
  methods: {
    async onSubmit() {
      this.isLoading = true;
      this.hasError = false;

      const res = await this.$http
        .post(
          `${process.env.API_SAFEWILL_COM__URL}/newsletter/contacts`,
          this.payload
        )
        .catch(() => {
          this.success = true;
          this.isLoading = false;
        });

      const { success } = await res.json();
      this.success = success;
      this.hasError = !success;
      this.isLoading = false;
    },
  },
};
