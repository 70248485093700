
export default {
  name: 'MktNewsItem',
  props: {
    /* eslint-disable-next-line vue/prop-name-casing */
    __typename: {
      required: true,
      type: String,
    },
    logo: {
      required: true,
      type: Array,
    },
    logoAspectRatio: {
      required: true,
      type: String,
    },
    heading: {
      required: true,
      type: String,
    },
    url: {
      required: true,
      type: String,
    },
  },
  computed: {
    classes() {
      return {
        'h-10 md:h-8 xl:h-10': this.logoAspectRatio === 'narrow',
        'h-8 md:h-6 xl:h-8': this.logoAspectRatio === 'normal',
        'h-5 md:h-4 xl:h-6': this.logoAspectRatio === 'wide',
      };
    },
  },
};
