
import { mapGetters } from 'vuex';

import GET_GUIDES_INDEX_QUERY from '~/graphql/queries/GetGuidesIndex';
import { craft, transition } from '~/mixins';

export default {
  name: 'PageGuides',
  mixins: [
    craft({
      query: GET_GUIDES_INDEX_QUERY,
      isLocalizable: true,
      customUri: 'guidance-notes',
      keepLocale: true,
    }),
    transition,
  ],
  data() {
    return {
      guides: [],
    };
  },
  computed: {
    ...mapGetters(['hasMobileLayout']),
  },
};
