import { render, staticRenderFns } from "./Testimonials.vue?vue&type=template&id=6b0fc6ca"
import script from "./Testimonials.vue?vue&type=script&lang=js"
export * from "./Testimonials.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MktQuoteMarks: require('/home/runner/work/ruckus/ruckus/apps/safewill.com/components/QuoteMarks.vue').default,MktArrowButton: require('/home/runner/work/ruckus/ruckus/apps/safewill.com/components/ArrowButton.vue').default,MktBaseAsset: require('/home/runner/work/ruckus/ruckus/apps/safewill.com/components/BaseAsset.vue').default})
