import { render, staticRenderFns } from "./vault.vue?vue&type=template&id=824e4062"
import script from "./vault.vue?vue&type=script&lang=js"
export * from "./vault.vue?vue&type=script&lang=js"
import style0 from "./vault.vue?vue&type=style&index=0&id=824e4062&prod&lang=css"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MktMarkedText: require('/home/runner/work/ruckus/ruckus/apps/safewill.com/components/MarkedText.vue').default,MktBaseAsset: require('/home/runner/work/ruckus/ruckus/apps/safewill.com/components/BaseAsset.vue').default,MktBaseHeader: require('/home/runner/work/ruckus/ruckus/apps/safewill.com/components/BaseHeader.vue').default,MktSocialReviews: require('/home/runner/work/ruckus/ruckus/apps/safewill.com/components/organisms/SocialReviews.vue').default,RuSection: require('/home/runner/work/ruckus/ruckus/packages/ruach/atoms/Section/index.vue').default,MktAppCardVault: require('/home/runner/work/ruckus/ruckus/apps/safewill.com/components/AppCardVault.vue').default})
