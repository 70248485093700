
import { mapGetters } from 'vuex';

export default {
  name: 'MktSaleBanner',
  props: {
    banner: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters(['partnership']),
    saleBannerIsVisible() {
      if (this.partnership)
        return this.banner.bannerEnabled && this.banner.overrideCharityBanner;
      return this.banner.bannerEnabled;
    },
    bannerUrl() {
      if (this.banner.imageLink) {
        return this.banner.imageLink;
      }
      let getStartedUrl = `${this.$config.safewillAppUrl}${this.localePath(
        `/get-started`
      )}`;
      if (this.banner.couponCode) {
        getStartedUrl += `?coupon=${this.banner.couponCode}`;
      }
      return getStartedUrl;
    },
    desktopSrcset() {
      return this.banner.desktopImage[0];
    },
    mobileSrcset() {
      return this.banner.mobileImage[0];
    },
  },
};
