
import { mapGetters } from 'vuex';
import KeenSlider from 'keen-slider';

export default {
  name: 'MktImageSlider',
  props: {
    go: {
      default: null,
      type: String,
    },
    assets: {
      required: true,
      type: Array,
    },
  },
  data() {
    return {
      keen: null,
      pause: false,
      interval: null,
    };
  },
  computed: {
    ...mapGetters(['hasMobileLayout']),
  },
  watch: {
    go(go) {
      if (this.keen && ['<', '>'].includes(go)) {
        this.keen[go === '<' ? 'prev' : 'next']();
      }
    },
  },
  mounted() {
    this.keen = new KeenSlider(this.$refs.keen, {
      slidesPerView: 1,
      centered: true,
      duration: 750,
      loop: true,
      mode: 'snap',
      dragStart: () => {
        this.setPause(true);
      },
      dragEnd: () => {
        this.setPause(false);
      },
    });
    this.setInterval();
  },
  beforeDestroy() {
    if (this.keen) {
      this.keen.destroy();
    }
  },
  methods: {
    setPause(active) {
      this.pause = active;
      this.setInterval();
    },
    resetInterval() {
      clearInterval(this.interval);
    },
    setInterval() {
      this.resetInterval();
      this.interval = setInterval(() => {
        if (!this.pause && this.assets.length > 1) {
          this.keen.next();
        }
      }, 3000);
    },
  },
};
