import { render, staticRenderFns } from "./how-it-works.vue?vue&type=template&id=803d1a00"
import script from "./how-it-works.vue?vue&type=script&lang=js"
export * from "./how-it-works.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MktMarkedText: require('/home/runner/work/ruckus/ruckus/apps/safewill.com/components/MarkedText.vue').default,MktVideoCard: require('/home/runner/work/ruckus/ruckus/apps/safewill.com/components/VideoCard.vue').default,MktBaseHeader: require('/home/runner/work/ruckus/ruckus/apps/safewill.com/components/BaseHeader.vue').default,MktSectionHeading: require('/home/runner/work/ruckus/ruckus/apps/safewill.com/components/SectionHeading.vue').default,MktArrowGraphic: require('/home/runner/work/ruckus/ruckus/apps/safewill.com/components/ArrowGraphic.vue').default,MktSignature: require('/home/runner/work/ruckus/ruckus/apps/safewill.com/components/snowflakes/Signature.vue').default,MktAppCardAnimation: require('/home/runner/work/ruckus/ruckus/apps/safewill.com/components/AppCardAnimation.vue').default,MktWillGraphic: require('/home/runner/work/ruckus/ruckus/apps/safewill.com/components/WillGraphic.vue').default,MktBaseParallaxBlock: require('/home/runner/work/ruckus/ruckus/apps/safewill.com/components/BaseParallaxBlock.vue').default,MktVideoModal: require('/home/runner/work/ruckus/ruckus/apps/safewill.com/components/VideoModal.vue').default})
