import { render, staticRenderFns } from "./default.vue?vue&type=template&id=a707651a"
import script from "./default.vue?vue&type=script&lang=js"
export * from "./default.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MktHeader: require('/home/runner/work/ruckus/ruckus/apps/safewill.com/components/organisms/Header.vue').default,MktPromoBanner: require('/home/runner/work/ruckus/ruckus/apps/safewill.com/components/organisms/PromoBanner.vue').default,MktViewport: require('/home/runner/work/ruckus/ruckus/apps/safewill.com/components/Viewport.vue').default,MktFooter: require('/home/runner/work/ruckus/ruckus/apps/safewill.com/components/Footer.vue').default,MktGridOverlay: require('/home/runner/work/ruckus/ruckus/apps/safewill.com/components/GridOverlay.vue').default,MktCountrySelectionBanner: require('/home/runner/work/ruckus/ruckus/apps/safewill.com/components/snowflakes/CountrySelectionBanner.vue').default})
