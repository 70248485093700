
export default {
  name: 'MktPricing',
  props: {
    data: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      pricingToggle: false,
    };
  },
  computed: {
    showPricingToggle() {
      return (
        [...new Set(this.data.products.map((product) => product.tab))].length >
        1
      );
    },
    productByTab() {
      return this.data.products.filter((product) => {
        return (
          product.tab.toUpperCase() ===
          (this.pricingToggle ? 'COUPLES' : 'INDIVIDUALS')
        );
      });
    },
  },
};
