
import { capitalize } from 'lodash';
import { mapActions } from 'vuex';

import GET_WILL_QUERY from '~/graphql/queries/GetWill';
import GET_INVITE_QUERY from '~/graphql/queries/GetInvite';
import { craft } from '~/mixins';
import { saveAnonymousProfile } from '~/util';

export default {
  name: 'PageHome',
  mixins: [
    craft({
      query: GET_WILL_QUERY,
    }),
  ],
  data() {
    return {
      entry: null,
      inviteLoaded: false,
    };
  },
  mounted() {
    this.loadInvite();
  },
  methods: {
    ...mapActions(['setTheme', 'setAppQuery']),
    ...mapActions('banner', ['setBannerText']),
    async loadInvite() {
      try {
        const { data } = await this.$apollo.query({
          query: GET_INVITE_QUERY,
          client: 'safewill',
          variables: {
            code: this.$route.params.code,
          },
        });
        const invite = data.getInvite;
        const name = invite.code.split('-')[0];
        this.setAppQuery([{ key: 'coupon', value: invite.code }]);
        if (invite.type === 'PROMO') {
          this.setBannerText(
            'Welcome to Safewill. Click "Get started" to write your Will today.'
          );
        } else {
          this.setBannerText(
            `${capitalize(name)} has invited you to get ${
              invite.type === 'PARTNER' ? 50 : 20
            }% off`
          );
        }
        saveAnonymousProfile({ couponCode: this.$route.params.code });
        this.setTheme([
          {
            background: '#67d7c0',
            logoBackground: '#67d7c0',
            text: '#ffffff',
            textAlternate: '#ffffff',
          },
        ]);
        this.inviteLoaded = true;
      } catch (e) {
        console.error(e.message);
        this.$router.push({
          path: this.localePath('/'),
          query: this.$route.query,
        });
      }
    },
  },
};
