
export default {
  name: 'MktProductGraphic',
  props: {
    asset: {
      required: true,
      type: Object,
    },
    type: {
      required: true,
      type: String,
      validate(type) {
        return ['will', 'poa', 'cremation', 'probate'].includes(type);
      },
    },
  },
  computed: {
    classes() {
      return {
        'lg:pb-8': this.isWill,
        'lg:pl-16 xl:pl-24 2xl:pl-40': this.isPoa,
        'lg:pl-10 xl:pl-18 2xl:pl-32': this.isCremation,
        'lg:pt-12 xl:pt-20': this.isProbate,
      };
    },
    fit() {
      return this.type === 'poa' ? 'height' : 'width';
    },
    imageClasses() {
      return {
        'lg:rounded-t-none': this.isWill,
        'lg:rounded-none': this.isPoa,
        'lg:rounded-t-none lg:rounded-r-none': this.isCremation,
        'lg:rounded-b-none lg:rounded-r-none': this.isProbate,
      };
    },
  },
};
