
import GET_AUTHOR_QUERY from '~/graphql/queries/GetAuthor';
import { transition } from '~/mixins';

export default {
  name: 'PageBlogPosts',
  mixins: [transition],
  apollo: {
    author: {
      query: GET_AUTHOR_QUERY,
      variables() {
        return {
          id: this.$route.params.id,
        };
      },
      update: (data) => data.author,
    },
  },
  computed: {
    filters() {
      return {
        authorId: this.$route.params.id,
      };
    },
  },
};
