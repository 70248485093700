
import anime from 'animejs';
import { mapGetters } from 'vuex';

export default {
  name: 'MktGridOverlay',
  computed: {
    ...mapGetters(['showGrid']),
  },
  methods: {
    enter(targets, complete) {
      anime({
        targets,
        opacity: [0, 1],
        duration: 150,
        easing: 'linear',
        complete,
      });
    },
    leave(targets, complete) {
      anime({
        targets,
        opacity: [1, 0],
        duration: 150,
        easing: 'linear',
        complete,
      });
    },
  },
};
