
import { mapActions } from 'vuex';

export default {
  name: 'MktVideoCard',
  props: {
    video: {
      type: Object,
      required: true,
    },
    videoThumbnail: {
      type: Object,
      required: true,
    },
    btnText: {
      type: String,
      default: 'Watch video',
    },
  },
  methods: {
    ...mapActions(['toggleVideoModal']),
  },
};
