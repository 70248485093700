
import anime from 'animejs';
import KeenSlider from 'keen-slider';

export default {
  name: 'MktSlider',
  data() {
    return {
      keen: null,
    };
  },
  mounted() {
    this.buildKeen();
  },
  beforeDestroy() {
    if (this.keen) {
      this.keen.destroy();
    }
  },
  methods: {
    buildKeen() {
      this.keen = new KeenSlider(this.$refs.keen, {
        breakpoints: {
          '(min-width: 768px)': {
            slidesPerView: 2,
            spacing: 24,
          },
          '(min-width: 1024px)': {
            slidesPerView: 2,
            spacing: 32,
          },
          '(min-width: 1280px)': {
            slidesPerView: 3,
            spacing: 32,
          },
          '(min-width: 1440px)': {
            slidesPerView: 4,
            spacing: 32,
          },
        },
        duration: 750,
        loop: true,
        spacing: 16,
      });
    },
    enter(targets, complete) {
      anime({
        targets,
        opacity: [0, 1],
        duration: 300,
        easing: 'easeInOutQuad',
        complete,
      });
    },
    leave(targets, complete) {
      anime({
        targets,
        opacity: [1, 0],
        duration: 300,
        easing: 'easeInOutQuad',
        complete,
      });
    },
  },
};
