import { render, staticRenderFns } from "./_slug.vue?vue&type=template&id=704cd656&scoped=true"
import script from "./_slug.vue?vue&type=script&lang=js"
export * from "./_slug.vue?vue&type=script&lang=js"
import style0 from "./_slug.vue?vue&type=style&index=0&id=704cd656&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "704cd656",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MktBlogTemplate: require('/home/runner/work/ruckus/ruckus/apps/safewill.com/components/BlogTemplate.vue').default})
