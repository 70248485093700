import { render, staticRenderFns } from "./GetStarted.vue?vue&type=template&id=69a4c51b"
import script from "./GetStarted.vue?vue&type=script&lang=js"
export * from "./GetStarted.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {RuButton: require('/home/runner/work/ruckus/ruckus/packages/ruach/molecules/Button/index.vue').default,RuButtons: require('/home/runner/work/ruckus/ruckus/packages/ruach/molecules/Buttons/index.vue').default})
