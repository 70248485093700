import { render, staticRenderFns } from "./CallToAction.vue?vue&type=template&id=6b684f52"
import script from "./CallToAction.vue?vue&type=script&lang=js"
export * from "./CallToAction.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {RuHeadline: require('/home/runner/work/ruckus/ruckus/packages/ruach/atoms/Headline/index.vue').default,MktGetStarted: require('/home/runner/work/ruckus/ruckus/apps/safewill.com/components/organisms/GetStarted.vue').default})
