
export default {
  name: 'MktCharitiesGraphic',
  props: {
    index: {
      required: true,
      type: Number,
    },
  },
};
