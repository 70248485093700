
export default {
  name: 'MktFaq',
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
};
