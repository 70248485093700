
export default {
  name: 'MktBaseImage',
  props: {
    alt: {
      default: '',
      type: String,
    },
    fit: {
      default: 'width',
      type: String,
      validator(fit) {
        return ['width', 'height', 'contain', 'cover', 'none'].includes(fit);
      },
    },
    sizes: {
      default: '100vw',
      type: String,
    },
    srcset: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      preload: false,
    };
  },
  computed: {
    imageClasses() {
      return {
        'object-contain': this.fit === 'contain',
        'object-cover': this.fit === 'cover',
        'object-none': this.fit === 'none',
        'opacity-0': !this.preload,
      };
    },
    wrapperClasses() {
      return {
        'w-full': ['width', 'contain', 'cover', 'none'].includes(this.fit),
        'h-full': ['height', 'contain', 'cover', 'none'].includes(this.fit),
      };
    },
  },
  methods: {
    onScroll() {
      const rect = this.$rect(this.$el, true);
      this.preload = rect.top < 1.5;

      return this.preload;
    },
  },
};
