
export default {
  name: 'MktSafewillLegalLogo',
  props: {
    mono: {
      default: false,
      type: Boolean,
    },
  },
};
