import { render, staticRenderFns } from "./BlogItemFeatured.vue?vue&type=template&id=77fe92a3&scoped=true"
import script from "./BlogItemFeatured.vue?vue&type=script&lang=js"
export * from "./BlogItemFeatured.vue?vue&type=script&lang=js"
import style0 from "./BlogItemFeatured.vue?vue&type=style&index=0&id=77fe92a3&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "77fe92a3",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MktBaseAsset: require('/home/runner/work/ruckus/ruckus/apps/safewill.com/components/BaseAsset.vue').default,MktBlogItemType: require('/home/runner/work/ruckus/ruckus/apps/safewill.com/components/BlogItemType.vue').default})
