
import { shuffle } from 'lodash';
export default {
  name: 'MktWillsFeaturesPrepare',
  data() {
    return {
      components: [
        'MktWillsFeaturesPrepareExecutors',
        'MktWillsFeaturesPrepareFamily',
        'MktWillsFeaturesPrepareGuardians',
        'MktWillsFeaturesPreparePets',
        'MktWillsFeaturesPrepareBeneficiaries',
      ],
    };
  },
  methods: {
    shuffle,
  },
};
