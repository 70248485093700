import { render, staticRenderFns } from "./MarkedText.vue?vue&type=template&id=2af54f6c"
import script from "./MarkedText.vue?vue&type=script&lang=js"
export * from "./MarkedText.vue?vue&type=script&lang=js"
import style0 from "./MarkedText.vue?vue&type=style&index=0&id=2af54f6c&prod&lang=css"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MktTextMarker: require('/home/runner/work/ruckus/ruckus/apps/safewill.com/components/TextMarker.vue').default})
