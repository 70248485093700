
import striptags from 'striptags';
export default {
  name: 'RuHeadline',
  props: {
    level: {
      default: 6,
      type: Number,
    },
    big: {
      default: false,
      type: Boolean,
    },
    row: {
      default: false,
      type: Boolean,
    },
    flush: {
      default: false,
      type: Boolean,
    },
    tight: {
      default: false,
      type: Boolean,
    },
    markup: {
      default: null,
      type: String,
    },
  },
  computed: {
    strippedText() {
      return striptags(this.markup?.replace(/<\/p>\s*<p>/g, '<br />'), [
        'br',
        'em',
        'small',
        'strong',
      ]);
    },
  },
};
