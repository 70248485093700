
export default {
  name: 'MktNavBarLink',
  props: {
    to: {
      required: false,
      type: String,
      default: '',
    },
    dropdown: {
      required: false,
      type: Array,
      default: () => [],
    },
    openInNewTab: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      hover: false,
    };
  },
  computed: {
    isActive() {
      return this.to === this.$route.path;
    },
    isExternalLink() {
      return this.to.substr(0, 1) !== '/';
    },
  },
};
