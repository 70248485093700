
export default {
  name: 'SiteMap',
  data() {
    return {
      sitemapData: [
        {
          title: 'Safewill',
          links: [{ name: 'Safewill', path: '/' }],
        },
        {
          title: 'Estate Planning',
          links: [
            { name: 'Wills', path: '/wills' },
            {
              name: 'Power of Attorney',
              path: '/power-of-attorney',
            },
            { name: 'Legal Advice', path: '/legal-advice' },
            { name: 'How it works', path: '/how-it-works' },
            { name: 'Pricing', path: '/pricing' },
          ],
        },
        {
          title: 'Resources',
          links: [
            { name: 'About us', path: '/about-us' },
            { name: 'Blog', path: '/blog' },
            { name: 'FAQs', url: 'https://safewill.zendesk.com/hc/en-us' },
          ],
        },
        {
          title: 'Charities',
          links: [
            { name: 'For Charities', path: '/charities' },
            {
              name: 'Charity Directory',
              path: '/charities/directory',
            },
          ],
        },
        {
          title: 'Compliance',
          links: [
            { name: 'Guidance Notes', path: '/guidance-notes' },
            { name: 'Privacy Policy', path: '/privacy' },
            { name: 'Security', path: '/security' },
            { name: 'Terms', path: '/terms' },
          ],
        },
      ],
    };
  },
  methods: {
    getFullUrl(link) {
      if (link.url) return link.url;
      return process.env.SAFEWILL_COM__URL + this.localePath(link.path);
    },
  },
};
