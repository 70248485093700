
export default {
  name: 'RuButtons',
  props: {
    labelSibling: {
      default: false,
      type: Boolean,
    },
    layout: {
      default: 'start',
      type: String,
    },
    flush: {
      default: false,
      type: Boolean,
    },
    small: {
      default: false,
      type: Boolean,
    },
  },
};
