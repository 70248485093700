
export default {
  name: 'MktNavBarLinkComponent',
  props: {
    to: {
      required: false,
      type: String,
      default: '',
    },
    openInNewTab: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    element() {
      if (this.isExternalLink) {
        return 'a';
      }

      return 'nuxt-link';
    },
    isExternalLink() {
      return this.to.substr(0, 1) !== '/';
    },
    newTabAttributes() {
      if (this.openInNewTab) {
        return {
          target: '_blank',
          rel: 'noreferrer',
        };
      }

      return null;
    },
  },
};
