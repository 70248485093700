
import anime from 'animejs';

export default {
  name: 'MktArrowGraphic',
  props: {
    direction: {
      default: null,
      type: String,
      validate(direction) {
        return ['up', 'left', 'right', 'down'].includes(direction);
      },
    },
  },
  computed: {
    classes() {
      return {
        'scale-x-flip': this.flip && !this.isVertical,
        'scale-y-flip': this.flip && this.isVertical,
      };
    },
    flip() {
      return ['up', 'left'].includes(this.direction);
    },
    isVertical() {
      return ['up', 'down'].includes(this.direction);
    },
    pathA() {
      return this.isVertical
        ? 'M20.5 1c.077 19.2.562 47.305 1 66.5.277 12.13.15 44.416 1.095 56.5'
        : 'M.25 20.75c19.2-.077 47.305-.562 66.5-1 12.13-.277 44.416-.15 56.5-1.095';
    },
    pathB() {
      return this.isVertical
        ? 'M36.5 96.375c-4.074 8.481-8.026 16.918-10.853 25.745-1.231 3.845-1.897 7.876-3.617 11.608-.41.887-.704-.663-.769-.856-1.174-3.493-2.098-7.042-3.301-10.528C13.758 110.168 10.976 100.121 5 88.5'
        : 'M95.625 4.75c8.481 4.074 16.918 8.026 25.745 10.853 3.845 1.231 7.876 1.897 11.608 3.617.887.41-.663.704-.856.769-3.493 1.174-7.042 2.098-10.528 3.301-12.176 4.202-22.223 6.984-33.844 12.96';
    },
    viewBox() {
      return `0 0 ${this.isVertical ? 41 : 138} ${this.isVertical ? 138 : 41}`;
    },
  },
  mounted() {
    anime.set(this.$refs.pathA, {
      strokeDashoffset: anime.setDashoffset,
    });

    if (this.direction) {
      anime.set(this.$refs.pathB, {
        strokeDashoffset: anime.setDashoffset,
      });
    }
  },
  methods: {
    onScroll() {
      const rect = this.$rect(this.$el, true);

      if (rect.isVisible) {
        const anim = anime.timeline();

        anim.add({
          targets: this.$refs.pathA,
          strokeDashoffset: 0,
          duration: 350,
          delay: this.isVertical ? 0 : rect.left * 2000,
          easing: 'easeInOutQuad',
        });

        if (this.direction) {
          anim.add({
            targets: this.$refs.pathB,
            strokeDashoffset: 0,
            duration: 350,
            easing: 'easeInOutQuad',
          });
        }
      }

      return rect.isVisible;
    },
  },
};
