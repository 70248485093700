
import GET_BLOG_POSTS_QUERY from '~/graphql/queries/GetBlogPosts';
import GET_BLOG_TOPICS_QUERY from '~/graphql/queries/GetBlogTopics';
import GET_BLOG_POST_COUNT_QUERY from '~/graphql/queries/GetBlogPostCount';

export default {
  name: 'MktPaginatedBlogPosts',
  props: {
    authorId: {
      default: null,
      type: [Number, String],
    },
    topicFilters: {
      default: false,
      type: Boolean,
    },
  },
  apollo: {
    blogPosts: {
      query: GET_BLOG_POSTS_QUERY,
      variables() {
        return {
          authorId: this.authorId,
          limit: this.postsPerPage,
          offset: (this.currentPage - 1) * this.postsPerPage,
          topic: this.topicsFilter,
        };
      },
      update: (data) => data.blogPosts,
      loadingKey: 'isLoading',
      prefetch: false,
    },
    postCount: {
      query: GET_BLOG_POST_COUNT_QUERY,
      variables() {
        return {
          authorId: this.authorId,
        };
      },
      update: (data) => data.count,
    },
    topics: {
      query: GET_BLOG_TOPICS_QUERY,
      update: (data) => {
        return data.topics.filter((topic) => topic.id);
      },
    },
  },
  data() {
    return {
      isLoading: 0,
    };
  },
  computed: {
    currentPage() {
      return this.$route.query.page ? parseInt(this.$route.query.page, 10) : 1;
    },
    disableNext() {
      return this.currentPage === this.totalPageCount;
    },
    disablePrevious() {
      return this.currentPage === 1;
    },
    pageNumbers() {
      const delta = 3;
      const left = this.currentPage - delta;
      const right = this.currentPage + delta + 1;
      const range = [];
      const rangeWithDots = [];
      let l;

      for (let i = 1; i <= this.totalPageCount; i++) {
        if (i === 1 || i === this.totalPageCount || (i >= left && i < right)) {
          range.push(i);
        }
      }

      for (const i of range) {
        if (l) {
          if (i - l === 2) {
            rangeWithDots.push(l + 1);
          } else if (i - l !== 1) {
            rangeWithDots.push('...');
          }
        }

        rangeWithDots.push(i);
        l = i;
      }

      l = delta;

      return rangeWithDots;
    },
    postsPerPage() {
      let count;

      switch (this.$mq) {
        case 'default':
        case 'sm':
          count = 5;
          break;
        case 'md':
          count = 6;
          break;
        default:
          count = 9;
          break;
      }

      return count;
    },
    topicsFilter() {
      return this.topicFilters && this.$route.query.topic
        ? [parseInt(this.$route.query.topic, 10)]
        : null;
    },
    totalPageCount() {
      return Math.ceil(this.postCount / this.postsPerPage);
    },
  },
  methods: {
    goToPage(page) {
      this.$router.push({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          page,
        },
      });
    },
    nextPage() {
      this.$router.push({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          page: this.currentPage + 1,
        },
      });
    },
    prevPage() {
      this.$router.push({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          page: this.currentPage - 1,
        },
      });
    },
  },
};
