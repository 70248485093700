import { render, staticRenderFns } from "./BlogItemPlaceholder.vue?vue&type=template&id=21a4e8fc&scoped=true"
import script from "./BlogItemPlaceholder.vue?vue&type=script&lang=js"
export * from "./BlogItemPlaceholder.vue?vue&type=script&lang=js"
import style0 from "./BlogItemPlaceholder.vue?vue&type=style&index=0&id=21a4e8fc&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21a4e8fc",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MktBlogItemType: require('/home/runner/work/ruckus/ruckus/apps/safewill.com/components/BlogItemType.vue').default})
