
import striptags from 'striptags';
import { removeEmptyElements } from '~/util';

export default {
  name: 'MktMarkedText',
  props: {
    strokeWidth: {
      default: null,
      type: [Number, String],
    },
    text: {
      required: true,
      type: String,
    },
    type: {
      required: true,
      type: String,
    },
  },
  computed: {
    afterText() {
      return this.hasHighlight ? this.strippedText.split('</em>')[1] : '';
    },
    beforeText() {
      return this.hasHighlight
        ? this.strippedText.split('<em>')[0]
        : this.strippedText;
    },
    hasHighlight() {
      return this.strippedText.includes('<em>');
    },
    highlightText() {
      return this.hasHighlight
        ? this.strippedText.split('<em>')[1].split('</em>')[0]
        : '';
    },
    strippedText() {
      const cleanHTML = removeEmptyElements(this.text);
      return striptags(cleanHTML.replace('</p><p>', '<br>'), [
        'br',
        'em',
        'small',
      ]);
    },
  },
};
