
import { mapGetters } from 'vuex';

import { collapseFieldArray } from '~/util';

export default {
  name: 'MktProductSwitcher',
  props: {
    products: {
      required: true,
      type: Array,
    },
    promoCode: {
      default: null,
      type: String,
    },
  },
  data() {
    return {
      currentIndex: 0,
    };
  },
  computed: {
    ...mapGetters(['partnership']),
    currentProduct() {
      return [this.products[this.currentIndex]];
    },
    productOverrideContent() {
      return this.partnership
        ? collapseFieldArray(this.partnership.willFeatureContent)
        : null;
    },
    getStartedPageUrl() {
      const currentPageUrl = this.currentProduct[0]?.getStartedPageUrl;
      if (!currentPageUrl) {
        return null;
      }
      if (!this.promoCode) {
        return currentPageUrl;
      }

      return `${currentPageUrl}?coupon=${this.promoCode}`;
    },
  },
  watch: {
    partnership(partnership) {
      if (partnership) {
        this.currentIndex = this.products.findIndex(
          (feature) => feature.slug === partnership.willFeatureOverride
        );
      }
    },
  },
  mounted() {
    if (this.partnership) {
      this.currentIndex = this.products.findIndex(
        (feature) => feature.slug === this.partnership.willFeatureOverride
      );
    }
  },
  methods: {
    getMergedBody(feature) {
      let body = feature.body;

      if (this.hasOverride(feature)) {
        body = this.willFeatureOverrideContent.body || body;
      }

      return body;
    },
    getMergedHeading(feature) {
      let heading = feature.heading;

      if (this.hasOverride(feature)) {
        heading = this.willFeatureOverrideContent.heading || heading;
      }

      return heading;
    },
    getMergedImage(feature) {
      let image = feature.image;

      if (this.hasOverride(feature)) {
        image = this.willFeatureOverrideContent.image[0] || image;
      }

      return image;
    },
    getStyles(feature) {
      return this.hasOverride(feature) &&
        this.willFeatureOverrideContent.backgroundColour
        ? {
            backgroundColor: this.willFeatureOverrideContent.backgroundColour,
            color: 'rgba(0, 0, 0, 0.5)',
          }
        : {};
    },
    getMarkerStyles(feature) {
      return this.hasOverride(feature) &&
        this.willFeatureOverrideContent.backgroundColour
        ? {
            color: this.willFeatureOverrideContent.backgroundColour,
          }
        : {};
    },
    next() {
      this.currentIndex = (this.currentIndex + 1) % this.products.length;
    },
    prev() {
      this.currentIndex =
        this.currentIndex === 0
          ? this.products.length - 1
          : (this.currentIndex - 1) % this.products.length;
    },
    hasOverride(feature) {
      return (
        this.partnership &&
        feature.slug === this.partnership.willFeatureOverride
      );
    },
  },
};
