
import anime from 'animejs';
import VDismiss from 'vue-dismiss';

export default {
  name: 'MktBaseModal',
  mixins: [VDismiss],
  props: {
    classes: {
      default: '',
      type: String,
    },
    dismissable: {
      default: false,
      type: Boolean,
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
    enter(_, complete) {
      const timeline = anime.timeline({
        autoplay: false,
        complete,
      });

      timeline
        .add({
          targets: this.$refs.background,
          opacity: [0, 1],
          duration: 300,
          easing: 'easeOutQuad',
        })
        .add(
          {
            targets: this.$refs.container,
            translateY: {
              value: [window.innerWidth / 10, 0],
              duration: 900,
              easing: 'easeOutExpo',
            },
            translateZ: 0,
            opacity: {
              value: [0, 1],
              duration: 200,
              easing: 'easeOutQuad',
            },
          },
          '-=300'
        );

      timeline.play();
    },
    leave(targets, complete) {
      anime({
        targets,
        opacity: [1, 0],
        duration: 300,
        easing: 'easeOutQuad',
        complete,
      });
    },
  },
};
