
export default {
  name: 'MktLegal',
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  computed: {
    lawyersAwards() {
      const awards = this.data.lawyers.map(
        (lawyer) => lawyer.awardImage[0].original
      );
      return Array.from(new Set(awards));
    },
  },
};
