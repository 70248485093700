
import { mapActions, mapGetters } from 'vuex';

import GET_OFFER_QUERY from '~/graphql/queries/GetOffer';
import { craft, transition } from '~/mixins';
import { collapseFieldArray } from '~/util';
import GET_HOME_QUERY from '@/graphql/queries/GetHome';

export default {
  name: 'PageOffer',
  mixins: [
    craft({
      query: GET_OFFER_QUERY,
      isLocalizable: true,
      transform({ entry }) {
        if (entry) {
          entry.content = collapseFieldArray(entry.content);
          entry.headerImage = collapseFieldArray(entry.headerImage);
        }

        return entry;
      },
      variables({ route }) {
        return {
          slug: route.name.split('___')[0],
        };
      },
      keepLocale: false,
    }),
    transition,
  ],
  data() {
    return {
      appQueryParams: {},
      bannerLogo: [],
      bannerText: '',
      content: {
        body: '',
        features: [],
        heading: '',
      },
      price: 0,
      strikethroughColour: '',
      themeColours: {},
    };
  },
  apollo: {
    home: {
      query: GET_HOME_QUERY,
      variables() {
        const countryCode = this.$i18n.locale.toUpperCase();
        return {
          country: countryCode,
        };
      },
      update: (data) => data.entry,
    },
  },
  computed: {
    ...mapGetters(['hasMobileLayout', 'theme']),
    featureStyles() {
      return this.theme
        ? {
            color: this.theme.textAlternate,
          }
        : {};
    },
    strikethroughStyles() {
      return {
        color: this.strikethroughColour,
      };
    },
    themeStyles() {
      return this.theme
        ? {
            backgroundColor: this.theme.background,
            color: this.theme.text,
          }
        : {};
    },
    socialReviews() {
      return this.home && this.home.socialReviews[0];
    },
  },
  mounted() {
    if (this.content.body) {
      this.setAppQuery(this.appQueryParams);
      this.setBannerLogo(this.bannerLogo);
      this.setBannerText(this.bannerText);
      this.setHasOffer(true);
      this.setTheme(this.themeColours);
    } else if (!this.loadingPartners) {
      this.$router.push({ path: this.localePath('/') });
    }
  },
  beforeDestroy() {
    this.setAppQuery([]);
    this.setBannerLogo('');
    this.setBannerText('');
    this.setHasOffer(false);
    this.setTheme({});
  },
  methods: {
    ...mapActions(['setAppQuery', 'setHasOffer', 'setTheme']),
    ...mapActions('banner', ['setBannerLogo', 'setBannerText']),
  },
};
