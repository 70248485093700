
export default {
  name: 'RuBox',
  props: {
    fit: {
      type: Boolean,
      default: false,
    },
    shadow: {
      type: Boolean,
      default: false,
    },
    tight: {
      type: Boolean,
      default: false,
    },
    flush: {
      type: Boolean,
      default: false,
    },
    thick: {
      type: Boolean,
      default: false,
    },
    background: {
      type: String,
      default: null,
    },
    border: {
      type: String,
      default: null,
    },
  },
  computed: {
    backgroundStyle() {
      if (this.background) {
        return `var(--${this.background})`;
      }
      return null;
    },
    borderColor() {
      if (this.border) {
        return `rgb(var(--${this.border}))`;
      }
      return null;
    },
    borderWidth() {
      if (this.thick) {
        return '0.125rem';
      } else if (this.border) {
        return '0.0625rem';
      }
      return null;
    },
    styles() {
      return {
        '--border-color': this.borderColor,
        '--border-width': this.borderWidth,
        '--background': this.backgroundStyle,
        '--margin-y': this.flush ? '0' : null,
      };
    },
  },
};
