import { render, staticRenderFns } from "./Support.vue?vue&type=template&id=082adda1"
import script from "./Support.vue?vue&type=script&lang=js"
export * from "./Support.vue?vue&type=script&lang=js"
import style0 from "./Support.vue?vue&type=style&index=0&id=082adda1&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {RuHeadline: require('/home/runner/work/ruckus/ruckus/packages/ruach/atoms/Headline/index.vue').default,RuButton: require('/home/runner/work/ruckus/ruckus/packages/ruach/molecules/Button/index.vue').default,RuButtons: require('/home/runner/work/ruckus/ruckus/packages/ruach/molecules/Buttons/index.vue').default})
