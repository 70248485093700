
export default {
  name: 'RuButton',
  props: {
    prepend: {
      default: null,
      type: String,
    },
    append: {
      default: null,
      type: String,
    },
    id: {
      default: null,
      type: String,
    },
    level: {
      default: null,
      type: String,
      validator(value) {
        return [
          null,
          'plain',
          'primary',
          'secondary',
          'tertiary',
          'option',
        ].includes(value);
      },
    },
    link: {
      default: null,
      type: String,
    },
    round: {
      default: false,
      type: Boolean,
    },
    square: {
      default: false,
      type: Boolean,
    },
    rounded: {
      default: false,
      type: Boolean,
    },
    icon: {
      default: null,
      type: String,
    },
    size: {
      default: null,
      type: String,
      validator(value) {
        return ['small', 'large'].includes(value);
      },
    },
    buttonType: {
      default: null,
      type: String,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
    theme: {
      default: null,
      type: String,
      validator(value) {
        return ['danger', 'facebook', 'whatsapp'].includes(value);
      },
    },
    active: {
      default: false,
      type: Boolean,
    },
    forceExternal: {
      default: false,
      type: Boolean,
    },
    forceInternal: {
      default: false,
      type: Boolean,
    },
    wrap: {
      default: false,
      type: Boolean,
    },
  },
  computed: {
    radiusStyle() {
      if (this.rounded || this.round) {
        return '99rem';
      }
      return null;
    },
    styles() {
      return {
        '--button-border-radius': this.radiusStyle,
      };
    },
    element() {
      if (!this.isAnchor) {
        return 'button';
      }
      return this.link.startsWith('/') ? 'nuxt-link' : 'a';
    },
    isAnchor() {
      return !!this.link;
    },
    isExternal() {
      return (
        !this.forceInternal &&
        (this.forceExternal || (this.isAnchor && !this.link.startsWith('/')))
      );
    },
    rel() {
      return this.isExternal ? 'noopener' : null;
    },
    target() {
      return this.isExternal ? '_blank' : null;
    },
    type() {
      return this.isAnchor ? null : this.buttonType || 'button';
    },
  },
  methods: {
    click({ target }) {
      target.blur();
      if (this.id) {
        this.$nuxt.$emit('sendTrackingEvent', {
          event: 'click',
          props: {
            element_id: `button_${this.id}`,
            page_path: this.$route.path,
          },
        });
      }
      this.$emit('click');
    },
  },
};
