
export default {
  name: 'MktHero',
  props: {
    srcset: {
      type: Object,
      default: null,
    },
  },
};
