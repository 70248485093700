
import { mapGetters } from 'vuex';
import reviewsData from '~/google-reviews.json';
import GET_HOME_QUERY from '@/graphql/queries/GetHome';
import { craft, partnerships, transition } from '~/mixins';

export default {
  name: 'PageHome',
  mixins: [
    craft({
      query: GET_HOME_QUERY,
      isLocalizable: true,
      customUri: 'index',
      keepLocale: true,
    }),
    partnerships,
    transition,
  ],
  data() {
    return {
      entry: null,
      reviewsData,
    };
  },
  head() {
    return {
      ...this.seomatic,
    };
  },
  computed: {
    ...mapGetters(['partnership', 'theme', 'hasPartnerCookie']),
    showSaleBanner() {
      if (!this.saleBanner.bannerEnabled) {
        return false;
      } else if (this.hasPartnerCookie === true) {
        return this.saleBanner.overrideCharityBanner;
      } else if (this.hasPartnerCookie === false) {
        return true;
      }
      return false;
    },
    saleBanner() {
      return this.entry.banner[0];
    },
    hero() {
      if (!this.partnership) {
        return this.entry.hero[0];
      }
      return {
        image: this.partnership.headerImage,
        title: this.partnership.headerContent[0].heading,
        content: `<p>${this.partnership.headerContent[0].body}</p>`,
      };
    },
    mediaTrustEnabled() {
      return this.entry.mediaTrust;
    },
    productOffering() {
      return this.entry.productOffering[0];
    },
    socialReviews() {
      return this.entry.socialReviews[0];
    },
    valuePropositions() {
      return this.entry.valuePropositions[0];
    },
    callToAction() {
      return this.entry.callToAction[0];
    },
    legal() {
      return this.entry.legal[0];
    },
    faq() {
      return this.entry.faqs[0];
    },
    support() {
      return this.entry.support[0];
    },
    showCharities() {
      return !this.hasPartnerCookie && this.entry.charities[0];
    },
    charities() {
      return this.entry.charities[0];
    },
    features() {
      return this.entry.features[0];
    },
  },
};
