
export default {
  name: 'MktCharitiesContactForm',
  data() {
    return {
      firstName: '',
      lastName: '',
      charityName: '',
      phoneNumber: '',
      email: '',
      jobTitle: '',
      message: '',
      heardFrom: '',
      heardFromOther: '',
      hasError: false,
      isLoading: false,
      success: false,
    };
  },
  computed: {
    submitLabel() {
      return this.success ? 'Done!' : 'Get in touch';
    },
    payload() {
      return {
        firstName: this.firstName,
        lastName: this.lastName,
        charityName: this.charityName,
        phoneNumber: this.phoneNumber,
        email: this.email,
        jobTitle: this.jobTitle,
        message: this.message,
        heardFrom:
          this.heardFrom === 'other' ? this.heardFromOther : this.heardFrom,
      };
    },
  },
  methods: {
    async onSubmit() {
      this.isLoading = true;
      this.hasError = false;

      await this.$http
        .post(
          `${this.$config.craftUrl}/actions/safewill-module/contact/charity`,
          this.payload
        )
        .then(async (res) => {
          const { success } = await res.json();
          this.success = success;
          this.hasError = !success;

          this.$pushCustomGTMEvent({
            event: 'charity-email-submission',
          });
        })
        .catch(() => {
          this.success = false;
          this.hasError = true;
        });

      this.isLoading = false;
    },
  },
};
