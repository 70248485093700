
import { mapGetters } from 'vuex';

export default {
  name: 'MktOnboardingButton',
  props: {
    arrow: {
      default: false,
      type: Boolean,
    },
    size: {
      default: 'small',
      type: String,
    },
    to: {
      type: String,
      default: '',
      required: false,
    },
    classes: {
      type: String,
      default: null,
    },
  },
  computed: {
    ...mapGetters(['appQuery', 'affiliateSlug', 'isReturning']),
    buttonSize() {
      switch (this.size) {
        case 'large':
          return 'button-lg rounded-lg';
        case 'medium':
          return 'button-md';
        case 'small':
          return 'button-sm';
        default:
          return null;
      }
    },
    buttonStyle() {
      return this.classes || `button button-navy ${this.buttonSize}`;
    },
    label() {
      return this.isReturning
        ? this.$t('common.buttons.finishYourWill')
        : this.$t('common.buttons.getStarted');
    },
    href() {
      const affiliateSlugAppend = this.affiliateSlug
        ? `/${this.affiliateSlug}`
        : '';
      return this.to
        ? this.to
        : this.isReturning
          ? `${this.$config.safewillAppUrl}${this.localePath(
              `${affiliateSlugAppend}/dashboard${this.appQuery}`
            )}`
          : `${this.$config.safewillAppUrl}${this.localePath(
              `${affiliateSlugAppend}/get-started${this.appQuery}`
            )}`;
    },
  },
};
