import { render, staticRenderFns } from "./WillFeatureSwitcher.vue?vue&type=template&id=62f61fca"
import script from "./WillFeatureSwitcher.vue?vue&type=script&lang=js"
export * from "./WillFeatureSwitcher.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MktTextMarker: require('/home/runner/work/ruckus/ruckus/apps/safewill.com/components/TextMarker.vue').default,MktWillFeatureText: require('/home/runner/work/ruckus/ruckus/apps/safewill.com/components/WillFeatureText.vue').default,MktArrowButton: require('/home/runner/work/ruckus/ruckus/apps/safewill.com/components/ArrowButton.vue').default,MktWillFeatureGraphic: require('/home/runner/work/ruckus/ruckus/apps/safewill.com/components/WillFeatureGraphic.vue').default})
