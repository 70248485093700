
import { mapGetters } from 'vuex';
import GET_HOME_QUERY from '@/graphql/queries/GetHome';

export default {
  name: 'PagesVault',
  data() {
    return {
      email: '',
      hasError: false,
      isLoading: false,
      isRegistered: false,
      headerContent: {
        heading: '<p>Secure your digital <em>assets</em></p>',
        body: 'Safewill will be releasing a way for you to secure your digital assets, passwords, logins and other digital items. These will only be seen and accessed by your benefactor upon your passing. Be the first to know when we release this.',
      },
      headerImage: [
        {
          filename: 'Vault-Header-Image.png',
          kind: 'image',
          lg: 'https://storage.googleapis.com/cms-safewill-operation-australia-southeast1/images/Vault-Header-Image.png',
          md: 'https://storage.googleapis.com/cms-safewill-operation-australia-southeast1/images/Vault-Header-Image.png',
          original:
            'https://storage.googleapis.com/cms-safewill-operation-australia-southeast1/images/Vault-Header-Image.png',
          sm: 'https://storage.googleapis.com/cms-safewill-operation-australia-southeast1/images/Vault-Header-Image.png',
          title: 'Vault Header Image',
          xl: 'https://storage.googleapis.com/cms-safewill-operation-australia-southeast1/images/Vault-Header-Image.png',
          xs: 'https://storage.googleapis.com/cms-safewill-operation-australia-southeast1/images/Vault-Header-Image.png',
        },
      ],
      contentImage: [
        {
          filename: 'Vault-Content-Image.png',
          kind: 'image',
          lg: 'https://storage.googleapis.com/cms-safewill-operation-australia-southeast1/images/Vault-Content-Image-01.png',
          md: 'https://storage.googleapis.com/cms-safewill-operation-australia-southeast1/images/Vault-Content-Image-01.png',
          original:
            'https://storage.googleapis.com/cms-safewill-operation-australia-southeast1/images/Vault-Content-Image-01.png',
          sm: 'https://storage.googleapis.com/cms-safewill-operation-australia-southeast1/images/Vault-Content-Image-01.png',
          title: 'Vault Content Image',
          xl: 'https://storage.googleapis.com/cms-safewill-operation-australia-southeast1/images/Vault-Content-Image-01.png',
          xs: 'https://storage.googleapis.com/cms-safewill-operation-australia-southeast1/images/Vault-Content-Image-01.png',
        },
      ],
      mission: [{ heading: '', statements: [] }],
      newsItems: [],
      team: [],
      teamSliderDirection: null,
    };
  },
  apollo: {
    home: {
      query: GET_HOME_QUERY,
      variables() {
        const countryCode = this.$i18n.locale.toUpperCase();
        return {
          country: countryCode,
        };
      },
      update: (data) => data.entry,
    },
  },
  head() {
    return {
      ...this.seomatic,
      script: [
        {
          src: 'https://app.mailjet.com/statics/js/iframeResizer.min.js',
        },
      ],
    };
  },
  computed: {
    ...mapGetters(['hasMobileLayout']),
    payload() {
      return {
        email: this.email,
      };
    },
    socialReviews() {
      return this.home && this.home.socialReviews[0];
    },
  },
  mounted() {
    const hash = this.$route?.hash.substr(1);
    if (hash === 'registered') {
      this.isRegistered = true;
    }
  },
};
