
import GET_BLOG_POST_QUERY from '~/graphql/queries/GetBlogPost';
import { craft, transition } from '~/mixins';

export default {
  name: 'PageBlogPost',
  mixins: [
    craft({
      query: GET_BLOG_POST_QUERY,
      variables({ route }) {
        const slug = route.path.split('/').pop();
        return {
          slug,
        };
      },
      keepLocale: false,
    }),
    transition,
  ],
  data() {
    return {
      entry: null,
    };
  },
};
