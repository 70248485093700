
import GET_WILL_QUERY from '~/graphql/queries/GetWill';
import { craft } from '~/mixins';

export default {
  name: 'PagePartner',
  mixins: [
    craft({
      query: GET_WILL_QUERY,
      isLocalizable: true,
      keepLocale: false,
    }),
  ],
  data() {
    return {
      entry: null,
    };
  },
};
