
import KeenSlider from 'keen-slider';

export default {
  name: 'MktTeamSlider',
  props: {
    go: {
      default: null,
      type: String,
    },
    team: {
      required: true,
      type: Array,
    },
  },
  data() {
    return {
      keen: null,
    };
  },
  watch: {
    go(go) {
      if (this.keen && ['<', '>'].includes(go)) {
        this.keen[go === '<' ? 'prev' : 'next']();
      }
    },
  },
  mounted() {
    this.keen = new KeenSlider(this.$refs.keen, {
      breakpoints: {
        '(min-width: 768px)': {
          slidesPerView: 3,
          spacing: 32,
          centered: false,
        },
        '(min-width: 1024px)': {
          slidesPerView: 4,
          spacing: 32,
          centered: false,
        },
        '(min-width: 1280px)': {
          slidesPerView: 4,
          spacing: 48,
          centered: false,
        },
        '(min-width: 1440px)': {
          slidesPerView: 4,
          spacing: 80,
          centered: false,
        },
      },
      slidesPerView: 2,
      centered: true,
      duration: 750,
      mode: 'free-snap',
    });
  },
};
