
import { mapActions, mapGetters } from 'vuex';
import { Portal } from 'portal-vue';

export default {
  name: 'MktVideoModal',
  components: {
    Portal,
  },
  props: {
    video: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters(['showVideoModal']),
  },
  methods: {
    ...mapActions(['toggleVideoModal']),
  },
};
