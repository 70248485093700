
import { mapActions } from 'vuex';
import GET_PROMOTION_QUERY from '~/graphql/queries/GetPromotion';
import GET_WILL_QUERY from '~/graphql/queries/GetWill';
import { craft } from '~/mixins';
import { promotions } from '~/vanityUrls.json';

export default {
  name: 'PagePromotion',
  mixins: [craft({ keepLocale: false })],
  data() {
    return {
      entry: null,
    };
  },
  async fetch() {
    const countryCode = this.$i18n.locale.toUpperCase();
    const routeName = this.$route.name?.split('___')[0];
    const promotionUrl = promotions.find(
      (p) => p.slug === routeName && p.country.includes(countryCode)
    );
    const promotionSlug = promotionUrl && promotionUrl.slug;

    const [willQuery, promotionQuery] = await Promise.all([
      this.$apollo.query({
        query: GET_WILL_QUERY,
        variables: {
          country: countryCode,
        },
      }),
      this.$apollo.query({
        query: GET_PROMOTION_QUERY,
        variables: {
          id: promotionSlug,
          country: countryCode,
        },
      }),
    ]);
    this.entry = this.mergeWillAndPromotionEntry(
      willQuery.data.entry,
      promotionQuery.data.entry
    );

    this.setAppQuery(promotionQuery.data.entry.appQueryParams);
  },
  methods: {
    ...mapActions(['setAppQuery']),
    mergeWillAndPromotionEntry(will, promotion) {
      const entry = { ...promotion };
      const fieldKeys = [
        'banner',
        'hero',
        'valuePropositions',
        'socialReviews',
        'features',
        'callToAction',
        'legal',
        'pricing',
        'articles',
        'faqs',
        'support',
      ];

      for (const fieldKey of fieldKeys) {
        if (
          promotion[fieldKey][0]?.enabled &&
          promotion[fieldKey][0]?.utiliseWillsEntryContent &&
          (will[fieldKey][0]?.enabled || will[fieldKey][0]?.bannerEnabled)
        ) {
          entry[fieldKey] = will[fieldKey];
        }
      }
      entry.mediaTrust = promotion.mediaTrust[0].enabled;
      return entry;
    },
  },
};
