
export default {
  name: 'MktProbateFeatureItem',
  props: {
    description: {
      required: true,
      type: String,
    },
    hideTick: {
      default: false,
      type: Boolean,
    },
    title: {
      required: true,
      type: String,
    },
  },
};
