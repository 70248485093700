import { render, staticRenderFns } from "./index.vue?vue&type=template&id=22fa8f51"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"
import style0 from "./index.vue?vue&type=style&index=0&id=22fa8f51&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {RuHeadline: require('/home/runner/work/ruckus/ruckus/packages/ruach/atoms/Headline/index.vue').default,MktHero: require('/home/runner/work/ruckus/ruckus/apps/safewill.com/components/organisms/Hero.vue').default,RuSection: require('/home/runner/work/ruckus/ruckus/packages/ruach/atoms/Section/index.vue').default,MktTextMarker: require('/home/runner/work/ruckus/ruckus/apps/safewill.com/components/TextMarker.vue').default,MktSectionHeading: require('/home/runner/work/ruckus/ruckus/apps/safewill.com/components/SectionHeading.vue').default})
