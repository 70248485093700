import { render, staticRenderFns } from "./PaginatedBlogPosts.vue?vue&type=template&id=03199c0d&scoped=true"
import script from "./PaginatedBlogPosts.vue?vue&type=script&lang=js"
export * from "./PaginatedBlogPosts.vue?vue&type=script&lang=js"
import style0 from "./PaginatedBlogPosts.vue?vue&type=style&index=0&id=03199c0d&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "03199c0d",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MktBlogTopic: require('/home/runner/work/ruckus/ruckus/apps/safewill.com/components/BlogTopic.vue').default,MktBlogItemPlaceholder: require('/home/runner/work/ruckus/ruckus/apps/safewill.com/components/BlogItemPlaceholder.vue').default,MktBlogItem: require('/home/runner/work/ruckus/ruckus/apps/safewill.com/components/BlogItem.vue').default})
