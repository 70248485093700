
export default {
  name: 'MktBlogArticles',
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
};
