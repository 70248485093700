import { render, staticRenderFns } from "./HomeFeatures.vue?vue&type=template&id=02f98b90"
import script from "./HomeFeatures.vue?vue&type=script&lang=js"
export * from "./HomeFeatures.vue?vue&type=script&lang=js"
import style0 from "./HomeFeatures.vue?vue&type=style&index=0&id=02f98b90&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {RuHeadline: require('/home/runner/work/ruckus/ruckus/packages/ruach/atoms/Headline/index.vue').default,MktHomeFeaturesWill: require('/home/runner/work/ruckus/ruckus/apps/safewill.com/components/snowflakes/home/features/Will.vue').default,MktHomeFeaturesPowerOfAttorney: require('/home/runner/work/ruckus/ruckus/apps/safewill.com/components/snowflakes/home/features/PowerOfAttorney.vue').default,MktHomeFeaturesVault: require('/home/runner/work/ruckus/ruckus/apps/safewill.com/components/snowflakes/home/features/Vault.vue').default,MktGetStarted: require('/home/runner/work/ruckus/ruckus/apps/safewill.com/components/organisms/GetStarted.vue').default,RuIcon: require('/home/runner/work/ruckus/ruckus/packages/ruach/atoms/Icon/index.vue').default,RuBox: require('/home/runner/work/ruckus/ruckus/packages/ruach/atoms/Box/index.vue').default})
