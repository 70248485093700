
import { mapGetters } from 'vuex';

export default {
  name: 'MktProduct',
  props: {
    product: {
      type: Object,
      default: null,
    },
  },
  computed: {
    ...mapGetters(['partnership', 'theme']),
    logo() {
      return this.product.companyLogo === 'safewillLegal'
        ? { variant: 'legal', color: '#77A396' }
        : { variant: undefined, color: undefined };
    },
    saleTagStyles() {
      return this.theme
        ? {
            '--sale-tag-background': this.theme.background,
            '--sale-tag-color': this.theme.text,
            '--sale-tag-label-color': this.theme.textActive,
          }
        : {};
    },
  },
  methods: {
    openProduct(url) {
      const isCalendlyLink = url.includes('calendly');
      const hasCalendly = typeof window.Calendly !== 'undefined';
      if (isCalendlyLink && hasCalendly) {
        window.Calendly.initPopupWidget({
          url,
        });
        return;
      }
      window.open(url);
    },
  },
};
