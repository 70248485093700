import { render, staticRenderFns } from "./BlogItem.vue?vue&type=template&id=4828c558"
import script from "./BlogItem.vue?vue&type=script&lang=js"
export * from "./BlogItem.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MktBaseAsset: require('/home/runner/work/ruckus/ruckus/apps/safewill.com/components/BaseAsset.vue').default,MktBlogItemType: require('/home/runner/work/ruckus/ruckus/apps/safewill.com/components/BlogItemType.vue').default})
