
export default {
  name: 'MktContentPage',
  props: {
    /* eslint-disable-next-line vue/prop-name-casing */
    __typename: {
      required: true,
      type: String,
    },
    textContent: {
      default: '',
      type: String,
    },
    title: {
      required: true,
      type: String,
    },
  },
};
