
import reviewsData from '~/google-reviews.json';
import GET_WILL_QUERY from '~/graphql/queries/GetWill';
import { craft, partnerships, transition } from '~/mixins';

export default {
  name: 'PageWills',
  mixins: [
    craft({
      query: GET_WILL_QUERY,
      isLocalizable: true,
      customUri: 'wills',
      keepLocale: true,
    }),
    partnerships,
    transition,
  ],
  data() {
    return {
      entry: null,
    };
  },
  head() {
    return {
      ...this.seomatic,
      script: [
        {
          json: {
            '@context': 'https://schema.org',
            '@type': 'Product',
            ...this.structuredWill,
          },
          type: 'application/ld+json',
        },
        {
          src: 'https://calendly.com/assets/external/widget.js',
          body: true,
          defer: true,
        },
      ],
      link: [
        {
          rel: 'stylesheet',
          href: 'https://calendly.com/assets/external/widget.css',
          body: true,
        },
      ],
    };
  },
  computed: {
    structuredWill() {
      return {
        '@type': 'Product',
        name: 'Safewill',
        description: `Australia's most trusted digital Will-writing platform. We've taken the stress, hassle and cost out of writing your bespoke legal Will.`,
        image:
          'https://storage.googleapis.com/cms-safewill-operation-australia-southeast1/images/groupshot-safewill.jpg',
        telephone: '1800103310',
        address: {
          '@type': 'PostalAddress',
          streetAddress: 'Level 7/28 Foveaux St',
          addressLocality: 'Australia',
          addressRegion: 'Surry Hills',
          postalCode: '2010',
          addressCountry: 'AU',
        },
        aggregateRating: {
          '@type': 'AggregateRating',
          ratingValue: reviewsData.averageRating,
          bestRating: '5',
          ratingCount: reviewsData.totalReviews,
        },
        review: {
          '@type': 'Review',
          reviewRating: {
            '@type': 'Rating',
            ratingValue: '5',
            bestRating: '5',
          },
          author: {
            '@type': 'Person',
            name: 'Debbie H',
          },
          reviewBody:
            'Easy to get through, easy to read. And easy to update for any changes. Best idea for wills! I highly recommend this for everyone. Leave a will, don’t ignore it for later',
        },
      };
    },
    saleBanner() {
      return this.entry.banner[0];
    },
  },
};
