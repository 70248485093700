
import { mapGetters } from 'vuex';

import GET_ABOUT_US_QUERY from '~/graphql/queries/GetAboutUs';
import { craft, transition, partnerships } from '~/mixins';
import { collapseFieldArray } from '~/util';

export default {
  name: 'PageAboutUs',
  mixins: [
    craft({
      query: GET_ABOUT_US_QUERY,
      isLocalizable: true,
      transform({ entry }) {
        entry.headerContent = collapseFieldArray(entry.headerContent);
        entry.mission = collapseFieldArray(entry.mission);

        return entry;
      },
    }),
    transition,
    partnerships,
  ],
  data() {
    return {
      headerContent: [{ heading: '', body: '' }],
      headerImage: [],
      mission: [{ heading: '', statements: [] }],
      newsItems: [],
      team: [],
      teamSliderDirection: null,
    };
  },
  computed: {
    ...mapGetters(['hasMobileLayout']),
  },
  methods: {
    sliderPrev() {
      this.$refs.teamSlider.keen.prev();
    },
    sliderNext() {
      this.$refs.teamSlider.keen.next();
    },
  },
};
