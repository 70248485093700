
export default {
  name: 'MktCharities',
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    logos() {
      const sizes = ['small', 'medium', 'large'];
      return this.data.charities.map((image) => ({
        image: [image],
        size: sizes[Math.floor(Math.random() * sizes.length)],
      }));
    },
  },
};
