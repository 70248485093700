
import { mapGetters } from 'vuex';
import GET_HOME_QUERY from '@/graphql/queries/GetHome';

export default {
  name: 'MktLegalAdviceContent',
  props: {
    entry: {
      default: null,
      type: Object,
    },
    promoPage: {
      default: false,
      type: Boolean,
    },
    promoCode: {
      default: null,
      type: String,
    },
  },
  apollo: {
    home: {
      query: GET_HOME_QUERY,
      variables() {
        const countryCode = this.$i18n.locale.toUpperCase();
        return {
          country: countryCode,
        };
      },
      update: (data) => data.entry,
    },
  },
  computed: {
    ...mapGetters(['hasMobileLayout', 'theme', 'partnership']),
    ...mapGetters('globals', ['phoneNumber', 'video', 'videoThumbnail']),
    onboardingTo() {
      return this.promoCode
        ? `${this.$config.safewillAppUrl}${this.localePath(
            `/get-started?coupon=${this.promoCode}`
          )}`
        : null;
    },
    logoStyles() {
      return this.theme
        ? {
            backgroundColor: this.theme.logoBackground,
          }
        : {};
    },
    markerStyles() {
      return this.theme &&
        this.partnership &&
        this.partnership.themedMarkerColour
        ? {
            color: this.theme.background,
          }
        : {};
    },
    socialReviews() {
      return this.home && this.home.socialReviews[0];
    },
  },
};
