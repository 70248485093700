
export default {
  name: 'MktAdiViewport',
  data() {
    return {
      height: 0,
      isLocked: false,
      scrollTop: 0,
    };
  },
  computed: {
    style() {
      return {
        top: this.isLocked ? `-${this.scrollTop}px` : null,
        height: this.isLocked ? `${this.height}px` : null,
      };
    },
  },
  mounted() {
    this.$nuxt.$on('lockViewport', this.lock);
    this.$nuxt.$on('unlockViewport', this.unlock);
  },
  methods: {
    lock() {
      this.height = this.$el.offsetHeight;
      this.isLocked = true;
    },
    onScroll(scrollTop) {
      if (!this.isLocked) {
        this.scrollTop = scrollTop;
      }
    },
    unlock() {
      this.isLocked = false;
      this.$scroll.trigger();
    },
  },
};
