
export default {
  name: 'MktAdiTestimonials',
  props: {
    testimonials: {
      required: true,
      type: Array,
    },
  },
  data() {
    return {
      currentIndex: 0,
    };
  },
  computed: {
    activeTestimonial() {
      return this.testimonials[this.currentIndex];
    },
  },
  methods: {
    nextTestimonial() {
      this.currentIndex = (this.currentIndex + 1) % this.testimonials.length;
    },
    prevTestimonial() {
      this.currentIndex =
        this.currentIndex === 0
          ? this.testimonials.length - 1
          : this.currentIndex - 1;
    },
  },
};
