import { render, staticRenderFns } from "./BlogItemCompact.vue?vue&type=template&id=41e39865&scoped=true"
import script from "./BlogItemCompact.vue?vue&type=script&lang=js"
export * from "./BlogItemCompact.vue?vue&type=script&lang=js"
import style0 from "./BlogItemCompact.vue?vue&type=style&index=0&id=41e39865&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "41e39865",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MktBlogItemType: require('/home/runner/work/ruckus/ruckus/apps/safewill.com/components/BlogItemType.vue').default,MktBaseAsset: require('/home/runner/work/ruckus/ruckus/apps/safewill.com/components/BaseAsset.vue').default})
