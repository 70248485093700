
export default {
  name: 'RuAccordionPanel',
  props: {
    headlineLevel: {
      default: 3,
      type: Number,
    },
  },
  data() {
    return {
      expanded: false,
    };
  },
  computed: {
    expandedClass() {
      return this.expanded ? '--expanded' : '';
    },
  },
  methods: {
    toggleAccordion() {
      this.expanded = !this.expanded;
    },
  },
};
