
export default {
  name: 'MktMenuButton',
  props: {
    mobileMenuIsOpen: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    openMenuClass() {
      return this.mobileMenuIsOpen ? '--mobile-menu-open' : null;
    },
  },
};
