
import anime from 'animejs';
import { mapGetters } from 'vuex';

export default {
  name: 'MktBaseParallaxBlock',
  props: {
    asset: {
      required: true,
      type: Object,
    },
    heading: {
      default: '',
      type: String,
    },
  },
  computed: {
    ...mapGetters(['hasMobileLayout']),
  },
  methods: {
    onScroll() {
      const deltaMax = Math.abs(
        this.$refs.image.$el.offsetHeight - this.$el.offsetHeight
      );

      if (!this.hasMobileLayout && deltaMax > 0) {
        const rect = this.$rect(this.$el, true);
        const translateY =
          ((rect.top + rect.height) / (1 + rect.height)) * deltaMax;

        anime.set(this.$refs.image.$el, {
          translateY: Math.min(deltaMax, Math.max(0, translateY)) * -1,
          translateZ: 0,
        });
      } else {
        anime.set(this.$refs.image.$el, {
          translateY: 0,
        });
      }
    },
  },
};
