
import { mapGetters } from 'vuex';

export default {
  name: 'MktAdiBanner',
  computed: {
    ...mapGetters(['theme']),
    ...mapGetters('banner', ['bannerName', 'bannerLogo', 'bannerText']),
    bannerStyles() {
      return this.theme
        ? {
            backgroundColor: this.theme.background,
            color: this.theme.text,
          }
        : null;
    },
    logoStyles() {
      return this.theme
        ? {
            backgroundColor: this.theme.logoBackground,
          }
        : null;
    },
  },
};
