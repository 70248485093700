
import { mapGetters } from 'vuex';

export default {
  name: 'MktAppCardCharityPartner',
  computed: {
    ...mapGetters(['partnership']),
    styles() {
      return {
        backgroundImage: `url(${this.partnership.intro[0].logo[0].xs})`,
      };
    },
  },
};
