
import GET_CHARITIES_QUERY from '~/graphql/queries/GetCharities';
import { craft, home, transition, partnerships } from '~/mixins';
import { collapseFieldArray } from '~/util';
import GET_HOME_QUERY from '@/graphql/queries/GetHome';

export default {
  name: 'PageCharities',
  mixins: [
    craft({
      query: GET_CHARITIES_QUERY,
      isLocalizable: true,
      transform({ entry }) {
        entry.headerContent = collapseFieldArray(entry.headerContent);
        return entry;
      },
    }),
    home,
    transition,
    partnerships,
  ],
  apollo: {
    home: {
      query: GET_HOME_QUERY,
      variables() {
        const countryCode = this.$i18n.locale.toUpperCase();
        return {
          country: countryCode,
        };
      },
      update: (data) => data.entry,
    },
  },
  data() {
    return {
      features: [{ heading: '', body: '' }],
      headerContent: [{ heading: '', body: '' }],
      intro: '',
      video: {
        title:
          '7NEWS-How-Safewill-is-helping-charities-secure-their-financial-future1',
        filename:
          '7NEWS-How-Safewill-is-helping-charities-secure-their-financial-future1.mp4',
        kind: 'video',
        url: 'https://storage.googleapis.com/cms-safewill-operation-australia-southeast1/videos/7NEWS-How-Safewill-is-helping-charities-secure-their-financial-future1.mp4',
        __typename: 'gcs_Asset',
      },
      videoThumbnail: {
        title: '7news-charities-thumb',
        filename: '7news-charities-thumb.png',
        kind: 'image',
        original:
          'https://storage.googleapis.com/cms-safewill-operation-australia-southeast1/images/7news-charities-thumb.png',
        xs: 'https://storage.googleapis.com/cms-safewill-operation-australia-southeast1/images/7news-charities-thumb.png',
        sm: 'https://storage.googleapis.com/cms-safewill-operation-australia-southeast1/images/7news-charities-thumb.png',
        md: 'https://storage.googleapis.com/cms-safewill-operation-australia-southeast1/images/7news-charities-thumb.png',
        lg: 'https://storage.googleapis.com/cms-safewill-operation-australia-southeast1/images/7news-charities-thumb.png',
        xl: 'https://storage.googleapis.com/cms-safewill-operation-australia-southeast1/images/7news-charities-thumb.png',
        __typename: 'gcs_Asset',
      },
      logos: [{ image: {}, size: '' }],
    };
  },
  computed: {
    socialReviews() {
      return this.home && this.home.socialReviews[0];
    },
  },
};
