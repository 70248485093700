
import { mapGetters } from 'vuex';

import blogItemProps from '~/modules/blog-item-props';

export default {
  name: 'MktBlogTemplate',
  props: {
    ...blogItemProps(),
    sections: {
      default: null,
      type: Array,
    },
    textContent: {
      default: null,
      type: String,
    },
    topics: {
      required: true,
      type: Array,
    },
  },
  head() {
    const script = [];
    try {
      if (
        this.structuredDataType.includes('howTo') &&
        this.howToStructuredData &&
        this.howToStructuredDataName
      ) {
        const structuredData = {
          '@context': 'https://schema.org',
          '@type': 'HowTo',
          name: this.howToStructuredDataName,
          step: this.howToStructuredData.map((step, index) => ({
            '@type': 'HowToStep',
            name: step.name,
            text: step.text,
            step: (index + 1).toString(),
          })),
        };
        script.push({
          json: structuredData,
          type: 'application/ld+json',
        });
      }

      if (this.structuredDataType.includes('faq') && this.faqStructuredData) {
        const structuredData = {
          '@context': 'https://schema.org',
          '@type': 'FAQPage',
          mainEntity: this.faqStructuredData.map((question) => ({
            '@type': 'Question',
            name: question.question,
            acceptedAnswer: {
              '@type': 'Answer',
              text: question.answer,
            },
          })),
        };
        script.push({
          json: structuredData,
          type: 'application/ld+json',
        });
      }

      if (
        this.structuredDataType.includes('custom') &&
        this.customStructuredData
      ) {
        const structuredData = JSON.parse(this.customStructuredData);
        script.push({
          json: structuredData,
          type: 'application/ld+json',
        });
      }
      if (script.length) {
        return {
          script,
        };
      }
      return {};
    } catch (err) {
      return {};
    }
  },
  computed: {
    ...mapGetters(['hasMobileLayout']),
  },
  methods: {
    onScroll() {
      if (!this.hasMobileLayout) {
        const rect = this.$rect(this.$refs.container);
        const maxY =
          this.$refs.container.offsetHeight - this.$refs.sidebar.offsetHeight;
        const translateY = Math.max(
          0,
          Math.min(maxY, this.$store.state.navBarHeight + 20 - rect.top)
        );

        this.$refs.sidebar.style.transform = `translate3d(0, ${translateY}px, 0)`;
      } else {
        this.$refs.sidebar.style.transform = null;
      }
    },
  },
};
