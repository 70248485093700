
import { mapGetters } from 'vuex';

export default {
  name: 'MktGetStarted',
  props: {
    level: {
      type: String,
      default: 'primary',
    },
    text: {
      type: String,
      default: null,
    },
    redirect: {
      type: String,
      default: '',
    },
    flush: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: null,
    },
    layout: {
      type: String,
      default: 'start',
    },
  },
  computed: {
    ...mapGetters(['appQuery', 'isReturning', 'affiliateSlug']),
    cta() {
      if (this.text) {
        return this.text;
      }
      return this.isReturning
        ? this.$t('common.buttons.finishYourWill')
        : this.$t('common.buttons.getStarted');
    },
    link() {
      const appQuery = this.appQuery;
      const redirect = this.redirect;
      let link = this.$config.safewillAppUrl;
      if (this.affiliateSlug) {
        link += `/${this.affiliateSlug}`;
      }
      if (this.isReturning) {
        link += '/dashboard';
      } else {
        link += '/get-started';
      }
      if (appQuery) {
        link += appQuery;
      }
      if (redirect) {
        link += link.includes('?') ? '&' : '?';
        link += `redirect=${redirect}`;
      }
      return link;
    },
  },
};
