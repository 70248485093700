
export default {
  name: 'MktBrandLogos',
  props: {
    brand: {
      required: true,
      type: String,
    },
  },
};
