
import { transition } from '~/mixins';

export default {
  name: 'PageBlogPosts',
  mixins: [transition],
  head() {
    return {
      title: 'Articles | Resources for writing your Will | Safewill',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content:
            "Modern life, modern Will. Write your Will today and safeguard your estate so you can focus on living. Safewill's blog contains comprehensive resources to help!",
        },
      ],
    };
  },
};
