
export default {
  name: 'MktNavigationItemChild',
  props: {
    to: {
      required: false,
      type: String,
      default: '',
    },
  },
  computed: {
    element() {
      if (this.isExternalLink) {
        return 'a';
      }
      return 'nuxt-link';
    },
    isExternalLink() {
      return this.to.substr(0, 1) !== '/';
    },
    newTabAttributes() {
      if (this.isExternalLink) {
        return {
          target: '_blank',
          rel: 'noreferrer',
        };
      }
      return null;
    },
  },
};
