
export default {
  name: 'MktAppCardModule',
  props: {
    number: {
      required: true,
      type: Number,
    },
    title: {
      required: true,
      type: String,
    },
    description: {
      default: null,
      type: String,
    },
    complete: {
      default: false,
      type: Boolean,
    },
  },
};
