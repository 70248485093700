
export default {
  name: 'MktFAQs',
  props: {
    items: {
      required: true,
      type: Array,
    },
  },
  data() {
    return {
      activeItems: [],
    };
  },
  methods: {
    isItemActive(item) {
      return this.activeItems.includes(item);
    },
    toggleItemActive(item) {
      const index = this.activeItems.indexOf(item);
      if (index > -1) {
        this.activeItems.splice(index, 1);
      } else {
        this.activeItems.push(item);
      }
    },
  },
};
