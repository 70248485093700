
import anime from 'animejs';

export default {
  name: 'MktCompareItem',
  props: {
    title: {
      required: false,
      type: String,
      default: '',
    },
  },
  data() {
    return {
      active: false,
    };
  },
  watch: {
    active(active) {
      const anim = anime.timeline({
        duration: 350,
        easing: 'easeInOutQuad',
      });

      if (active) {
        this.$emit('active');

        anim
          .add({
            targets: this.$refs.pathA,
            rotate: 90,
          })
          .add(
            {
              targets: this.$refs.pathB,
              rotate: 180,
            },
            0
          );
      } else {
        anim.add({
          targets: [this.$refs.pathA, this.$refs.pathB],
          rotate: 0,
        });
      }
    },
  },
  mounted() {
    anime.set([this.$refs.pathA, this.$refs.pathB], {
      transformOrigin: `${this.$refs.svg.clientWidth / 2}px ${
        this.$refs.svg.clientHeight / 2
      }px 0px`,
    });
    this.active = true;
  },
};
