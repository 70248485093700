
export default {
  name: 'MktTextMarker',
  props: {
    active: {
      default: true,
      type: Boolean,
    },
    strokeWidth: {
      default: null,
      type: [Number, String],
    },
    type: {
      required: true,
      type: String,
    },
  },
  computed: {
    style() {
      const style = {
        top: '0',
        left: '0',
        right: '0',
        bottom: '0',
      };

      switch (this.type) {
        case 'circle-fill':
          style.top = '-0.07em';
          style.left = '-0.17em';
          style.right = '-0.02em';
          style.bottom = '-0.25em';
          break;
        case 'circle-stroke':
          style.top = '0.1em';
          style.left = '-0.15em';
          style.right = '0.15em';
          style.bottom = '-0.1em';
          break;
        case 'line-a':
          style.top = '0.92em';
          style.right = '0.25em';
          style.bottom = '-0.2em';
          break;
        case 'line-b':
          style.top = '0.4em';
          style.left = '0.15em';
          style.right = '0.2em';
          style.bottom = '0.25em';
          break;
        case 'line-c':
          style.top = '0.9em';
          style.left = '-0.2em';
          style.right = '0.3em';
          style.bottom = '0em';
          break;
        default:
          break;
      }

      return style;
    },
  },
  // mounted() {
  //   this.animation = anime({
  //     targets: this.$refs.svg.querySelector('path'),
  //     strokeDashoffset: [anime.setDashoffset, 0],
  //     duration: 600,
  //     easing: 'easeInOutQuad',
  //     autoplay: false
  //   })
  // }
};
