
import anime from 'animejs';
import { mapGetters } from 'vuex';

import GET_GUIDE_QUERY from '~/graphql/queries/GetGuide';
import { craft, transition } from '~/mixins';

export default {
  name: 'PageGuide',
  mixins: [
    craft({
      query: GET_GUIDE_QUERY,
      isLocalizable: true,
      variables({ params }) {
        return {
          slug: params.slug,
        };
      },
      keepLocale: false,
    }),
    transition,
  ],
  data() {
    return {
      currentSectionIndex: 0,
      entry: null,
    };
  },
  computed: {
    ...mapGetters(['navBarHeight']),
  },
  watch: {
    currentSectionIndex(currentSectionIndex) {
      anime.remove(this.$refs.marker);

      anime({
        targets: this.$refs.marker,
        translateY: this.$refs.listItems[currentSectionIndex].offsetTop,
        translateZ: 0,
        duration: 200,
        easing: 'easeInOutQuad',
      });
    },
  },
  methods: {
    onScroll() {
      if (!this.$store.state.hasMobileLayout) {
        for (let i = 0; i < this.$refs.sections.length; i++) {
          if (this.$rect(this.$refs.sections[i]).top < this.navBarHeight * 2) {
            this.currentSectionIndex = i;
          } else {
            i = this.$refs.sections.length;
          }
        }
      }
    },
    scrollToSection(index) {
      const rect = this.$rect(this.$refs.sections[index]);

      anime({
        targets: this.$scroll.element,
        scrollTop: rect.top + this.$scroll.top() - this.navBarHeight,
        duration: 1500,
        easing: 'easeInOutQuad',
      });
    },
  },
};
