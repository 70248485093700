
import { mapGetters } from 'vuex';

export default {
  name: 'MktProbateProductCard',
  props: {
    description: {
      required: true,
      type: String,
    },
    price: {
      required: true,
      type: Number,
    },
    title: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      isExpanded: false,
    };
  },
  computed: {
    ...mapGetters(['hasMobileLayout']),
    buttonLabel() {
      return `Show ${this.isExpanded ? 'less' : 'more'}`;
    },
  },
};
