
export default {
  name: 'MktWillFeatureText',
  props: {
    body: {
      required: true,
      type: String,
    },
    heading: {
      required: true,
      type: String,
    },
    title: {
      required: true,
      type: String,
    },
  },
};
